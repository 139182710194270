import request from '@/utils/request'

// 专线
export function listConfig(query) {
  return request({
    url: `/user/logistics/specialLine?pageNum=${query.pageNum}&pageSize=10&startLocation=${query.startLocation}&endLocation=${query.endLocation}`,
    method: 'get',
    // params: query
  })
}
// 三方
export function tripartiteAPI(query) {
  return request({
    url: `/user/logistics/commonLine?companyCategory=${query.companyCategory}&pageNum=${query.pageNum}&pageSize=${query.pageSize}&province=${query.province}&city=${query.city}`,
    method: 'get',
    // params: query
  })
}

// 专用车
export function specialcarAPI(query) {
  return request({
    url: "/user/logistics/commonLine",
    method: 'get',
        params: query
  })
}
// 专线搜索
export function getConfig(query) {
  return request({
    url: "/transport/sendRoute/webSpecialLine",
    method: 'get',
        params: query
  })
}
// 物流详情页
export function getDetailpage(query) {
  return request({
    url: "/system/company/detailLine",
    method: 'get',
        params: query
  })
}

// 根据参数键名查询参数值
export function getConfigKey(configKey) {
  return request({
    url: '/system/config/configKey/' + configKey,
    method: 'get'
  })
}

// 新增参数配置
export function addConfig(data) {
  return request({
    url: '/system/config',
    method: 'post',
    data: data
  })
}

// 修改参数配置
export function updateConfig(data) {
  return request({
    url: '/system/config',
    method: 'put',
    data: data
  })
}

// 删除参数配置
export function delConfig(configId) {
  return request({
    url: '/system/config/' + configId,
    method: 'delete'
  })
}

// 刷新参数缓存
export function refreshCache() {
  return request({
    url: '/system/config/refreshCache',
    method: 'delete'
  })
}