let i = {
    北京市: {
        北京市: [
        "东城区",
        "西城区",
        "朝阳区",
        "丰台区",
        "石景山区",
        "海淀区",
        "门头沟区",
        "房山区",
        "通州区",
        "顺义区",
        "昌平区",
        "大兴区",
        "怀柔区",
        "平谷区",
        "密云区",
        "延庆区"
      ]
    },
    天津市: {
        天津市: [
        "和平区",
        "河东区",
        "河西区",
        "南开区",
        "河北区",
        "红桥区",
        "东丽区",
        "西青区",
        "津南区",
        "北辰区",
        "武清区",
        "宝坻区",
        "滨海新区",
        "宁河区",
        "静海区",
        "蓟州区"
      ]
    },
    河北省: {
      石家庄市: [
        "长安区",
        "桥西区",
        "新华区",
        "井陉矿区",
        "裕华区",
        "藁城区",
        "鹿泉区",
        "栾城区",
        "井陉县",
        "正定县",
        "行唐县",
        "灵寿县",
        "高邑县",
        "深泽县",
        "赞皇县",
        "无极县",
        "平山县",
        "元氏县",
        "赵县",
        "石家庄高新技术产业开发区",
        "石家庄循环化工园区",
        "辛集市",
        "晋州市",
        "新乐市"
      ],
      唐山市: [
        "路南区",
        "路北区",
        "古冶区",
        "开平区",
        "丰南区",
        "丰润区",
        "曹妃甸区",
        "滦南县",
        "乐亭县",
        "迁西县",
        "玉田县",
        "河北唐山芦台经济开发区",
        "唐山市汉沽管理区",
        "唐山高新技术产业开发区",
        "河北唐山海港经济开发区",
        "遵化市",
        "迁安市",
        "滦州市"
      ],
      秦皇岛市: [
        "海港区",
        "山海关区",
        "北戴河区",
        "抚宁区",
        "青龙满族自治县",
        "昌黎县",
        "卢龙县",
        "秦皇岛市经济技术开发区",
        "北戴河新区"
      ],
      邯郸市: [
        "邯山区",
        "丛台区",
        "复兴区",
        "峰峰矿区",
        "肥乡区",
        "永年区",
        "临漳县",
        "成安县",
        "大名县",
        "涉县",
        "磁县",
        "邱县",
        "鸡泽县",
        "广平县",
        "馆陶县",
        "魏县",
        "曲周县",
        "邯郸经济技术开发区",
        "邯郸冀南新区",
        "武安市"
      ],
      邢台市: [
        "襄都区",
        "信都区",
        "任泽区",
        "南和区",
        "临城县",
        "内丘县",
        "柏乡县",
        "隆尧县",
        "宁晋县",
        "巨鹿县",
        "新河县",
        "广宗县",
        "平乡县",
        "威县",
        "清河县",
        "临西县",
        "河北邢台经济开发区",
        "南宫市",
        "沙河市"
      ],
      保定市: [
        "竞秀区",
        "莲池区",
        "满城区",
        "清苑区",
        "徐水区",
        "涞水县",
        "阜平县",
        "定兴县",
        "唐县",
        "高阳县",
        "容城县",
        "涞源县",
        "望都县",
        "安新县",
        "易县",
        "曲阳县",
        "蠡县",
        "顺平县",
        "博野县",
        "雄县",
        "保定高新技术产业开发区",
        "保定白沟新城",
        "涿州市",
        "定州市",
        "安国市",
        "高碑店市"
      ],
      张家口市: [
        "桥东区",
        "桥西区",
        "宣化区",
        "下花园区",
        "万全区",
        "崇礼区",
        "张北县",
        "康保县",
        "沽源县",
        "尚义县",
        "蔚县",
        "阳原县",
        "怀安县",
        "怀来县",
        "涿鹿县",
        "赤城县",
        "张家口经济开发区",
        "张家口市察北管理区",
        "张家口市塞北管理区"
      ],
      承德市: [
        "双桥区",
        "双滦区",
        "鹰手营子矿区",
        "承德县",
        "兴隆县",
        "滦平县",
        "隆化县",
        "丰宁满族自治县",
        "宽城满族自治县",
        "围场满族蒙古族自治县",
        "承德高新技术产业开发区",
        "平泉市"
      ],
      沧州市: [
        "新华区",
        "运河区",
        "沧县",
        "青县",
        "东光县",
        "海兴县",
        "盐山县",
        "肃宁县",
        "南皮县",
        "吴桥县",
        "献县",
        "孟村回族自治县",
        "河北沧州经济开发区",
        "沧州高新技术产业开发区",
        "沧州渤海新区",
        "泊头市",
        "任丘市",
        "黄骅市",
        "河间市"
      ],
      廊坊市: [
        "安次区",
        "广阳区",
        "固安县",
        "永清县",
        "香河县",
        "大城县",
        "文安县",
        "大厂回族自治县",
        "廊坊经济技术开发区",
        "霸州市",
        "三河市"
      ],
      衡水市: [
        "桃城区",
        "冀州区",
        "枣强县",
        "武邑县",
        "武强县",
        "饶阳县",
        "安平县",
        "故城县",
        "景县",
        "阜城县",
        "河北衡水高新技术产业开发区",
        "衡水滨湖新区",
        "深州市"
      ]
    },
    山西省: {
      太原市: [
        "小店区",
        "迎泽区",
        "杏花岭区",
        "尖草坪区",
        "万柏林区",
        "晋源区",
        "清徐县",
        "阳曲县",
        "娄烦县",
        "山西转型综合改革示范区",
        "古交市"
      ],
      大同市: [
        "新荣区",
        "平城区",
        "云冈区",
        "云州区",
        "阳高县",
        "天镇县",
        "广灵县",
        "灵丘县",
        "浑源县",
        "左云县",
        "山西大同经济开发区"
      ],
      阳泉市: [
        "城区",
        "矿区",
        "郊区",
        "平定县",
        "盂县"
      ],
      长治市: [
        "潞州区",
        "上党区",
        "屯留区",
        "潞城区",
        "襄垣县",
        "平顺县",
        "黎城县",
        "壶关县",
        "长子县",
        "武乡县",
        "沁县",
        "沁源县"
      ],
      晋城市: [
        "城区",
        "沁水县",
        "阳城县",
        "陵川县",
        "泽州县",
        "高平市"
      ],
      朔州市: [
        "朔城区",
        "平鲁区",
        "山阴县",
        "应县",
        "右玉县",
        "山西朔州经济开发区",
        "怀仁市"
      ],
      晋中市: [
        "榆次区",
        "太谷区",
        "榆社县",
        "左权县",
        "和顺县",
        "昔阳县",
        "寿阳县",
        "祁县",
        "平遥县",
        "灵石县",
        "介休市"
      ],
      运城市: [
        "盐湖区",
        "临猗县",
        "万荣县",
        "闻喜县",
        "稷山县",
        "新绛县",
        "绛县",
        "垣曲县",
        "夏县",
        "平陆县",
        "芮城县",
        "永济市",
        "河津市"
      ],
      忻州市: [
        "忻府区",
        "定襄县",
        "五台县",
        "代县",
        "繁峙县",
        "宁武县",
        "静乐县",
        "神池县",
        "五寨县",
        "岢岚县",
        "河曲县",
        "保德县",
        "偏关县",
        "五台山风景名胜区",
        "原平市"
      ],
      临汾市: [
        "尧都区",
        "曲沃县",
        "翼城县",
        "襄汾县",
        "洪洞县",
        "古县",
        "安泽县",
        "浮山县",
        "吉县",
        "乡宁县",
        "大宁县",
        "隰县",
        "永和县",
        "蒲县",
        "汾西县",
        "侯马市",
        "霍州市"
      ],
      吕梁市: [
        "离石区",
        "文水县",
        "交城县",
        "兴县",
        "临县",
        "柳林县",
        "石楼县",
        "岚县",
        "方山县",
        "中阳县",
        "交口县",
        "孝义市",
        "汾阳市"
      ]
    },
    内蒙古: {
      呼和浩特市: [
        "新城区",
        "回民区",
        "玉泉区",
        "赛罕区",
        "土默特左旗",
        "托克托县",
        "和林格尔县",
        "清水河县",
        "武川县",
        "呼和浩特经济技术开发区"
      ],
      包头市: [
        "东河区",
        "昆都仑区",
        "青山区",
        "石拐区",
        "白云鄂博矿区",
        "九原区",
        "土默特右旗",
        "固阳县",
        "达尔罕茂明安联合旗",
        "包头稀土高新技术产业开发区"
      ],
      乌海市: [
        "海勃湾区",
        "海南区",
        "乌达区"
      ],
      赤峰市: [
        "红山区",
        "元宝山区",
        "松山区",
        "阿鲁科尔沁旗",
        "巴林左旗",
        "巴林右旗",
        "林西县",
        "克什克腾旗",
        "翁牛特旗",
        "喀喇沁旗",
        "宁城县",
        "敖汉旗"
      ],
      通辽市: [
        "科尔沁区",
        "科尔沁左翼中旗",
        "科尔沁左翼后旗",
        "开鲁县",
        "库伦旗",
        "奈曼旗",
        "扎鲁特旗",
        "通辽经济技术开发区",
        "霍林郭勒市"
      ],
      鄂尔多斯市: [
        "东胜区",
        "康巴什区",
        "达拉特旗",
        "准格尔旗",
        "鄂托克前旗",
        "鄂托克旗",
        "杭锦旗",
        "乌审旗",
        "伊金霍洛旗"
      ],
      呼伦贝尔市: [
        "海拉尔区",
        "扎赉诺尔区",
        "阿荣旗",
        "莫力达瓦达斡尔族自治旗",
        "鄂伦春自治旗",
        "鄂温克族自治旗",
        "陈巴尔虎旗",
        "新巴尔虎左旗",
        "新巴尔虎右旗",
        "满洲里市",
        "牙克石市",
        "扎兰屯市",
        "额尔古纳市",
        "根河市"
      ],
      巴彦淖尔市: [
        "临河区",
        "五原县",
        "磴口县",
        "乌拉特前旗",
        "乌拉特中旗",
        "乌拉特后旗",
        "杭锦后旗"
      ],
      乌兰察布市: [
        "集宁区",
        "卓资县",
        "化德县",
        "商都县",
        "兴和县",
        "凉城县",
        "察哈尔右翼前旗",
        "察哈尔右翼中旗",
        "察哈尔右翼后旗",
        "四子王旗",
        "丰镇市"
      ],
      兴安盟: [
        "乌兰浩特市",
        "阿尔山市",
        "科尔沁右翼前旗",
        "科尔沁右翼中旗",
        "扎赉特旗",
        "突泉县"
      ],
      锡林郭勒盟: [
        "二连浩特市",
        "锡林浩特市",
        "阿巴嘎旗",
        "苏尼特左旗",
        "苏尼特右旗",
        "东乌珠穆沁旗",
        "西乌珠穆沁旗",
        "太仆寺旗",
        "镶黄旗",
        "正镶白旗",
        "正蓝旗",
        "多伦县",
        "乌拉盖管理区管委会"
      ],
      阿拉善盟: [
        "阿拉善左旗",
        "阿拉善右旗",
        "额济纳旗",
        "内蒙古阿拉善高新技术产业开发区"
      ]
    },
    辽宁省: {
      沈阳市: [
        "和平区",
        "沈河区",
        "大东区",
        "皇姑区",
        "铁西区",
        "苏家屯区",
        "浑南区",
        "沈北新区",
        "于洪区",
        "辽中区",
        "康平县",
        "法库县",
        "新民市"
      ],
      大连市: [
        "中山区",
        "西岗区",
        "沙河口区",
        "甘井子区",
        "旅顺口区",
        "金州区",
        "普兰店区",
        "长海县",
        "瓦房店市",
        "庄河市"
      ],
      鞍山市: [
        "铁东区",
        "铁西区",
        "立山区",
        "千山区",
        "台安县",
        "岫岩满族自治县",
        "海城市"
      ],
      抚顺市: [
        "新抚区",
        "东洲区",
        "望花区",
        "顺城区",
        "抚顺县",
        "新宾满族自治县",
        "清原满族自治县"
      ],
      本溪市: [
        "平山区",
        "溪湖区",
        "明山区",
        "南芬区",
        "本溪满族自治县",
        "桓仁满族自治县"
      ],
      丹东市: [
        "元宝区",
        "振兴区",
        "振安区",
        "宽甸满族自治县",
        "东港市",
        "凤城市"
      ],
      锦州市: [
        "古塔区",
        "凌河区",
        "太和区",
        "黑山县",
        "义县",
        "凌海市",
        "北镇市"
      ],
      营口市: [
        "站前区",
        "西市区",
        "鲅鱼圈区",
        "老边区",
        "盖州市",
        "大石桥市"
      ],
      阜新市: [
        "海州区",
        "新邱区",
        "太平区",
        "清河门区",
        "细河区",
        "阜新蒙古族自治县",
        "彰武县"
      ],
      辽阳市: [
        "白塔区",
        "文圣区",
        "宏伟区",
        "弓长岭区",
        "太子河区",
        "辽阳县",
        "灯塔市"
      ],
      盘锦市: [
        "双台子区",
        "兴隆台区",
        "大洼区",
        "盘山县"
      ],
      铁岭市: [
        "银州区",
        "清河区",
        "铁岭县",
        "西丰县",
        "昌图县",
        "调兵山市",
        "开原市"
      ],
      朝阳市: [
        "双塔区",
        "龙城区",
        "朝阳县",
        "建平县",
        "喀喇沁左翼蒙古族自治县",
        "北票市",
        "凌源市"
      ],
      葫芦岛市: [
        "连山区",
        "龙港区",
        "南票区",
        "绥中县",
        "建昌县",
        "兴城市"
      ]
    },
    吉林省: {
      长春市: [
        "南关区",
        "宽城区",
        "朝阳区",
        "二道区",
        "绿园区",
        "双阳区",
        "九台区",
        "农安县",
        "长春经济技术开发区",
        "长春净月高新技术产业开发区",
        "长春高新技术产业开发区",
        "长春汽车经济技术开发区",
        "榆树市",
        "德惠市",
        "公主岭市"
      ],
      吉林市: [
        "昌邑区",
        "龙潭区",
        "船营区",
        "丰满区",
        "永吉县",
        "吉林经济开发区",
        "吉林高新技术产业开发区",
        "吉林中国新加坡食品区",
        "蛟河市",
        "桦甸市",
        "舒兰市",
        "磐石市"
      ],
      四平市: [
        "铁西区",
        "铁东区",
        "梨树县",
        "伊通满族自治县",
        "双辽市"
      ],
      辽源市: [
        "龙山区",
        "西安区",
        "东丰县",
        "东辽县"
      ],
      通化市: [
        "东昌区",
        "二道江区",
        "通化县",
        "辉南县",
        "柳河县",
        "梅河口市",
        "集安市"
      ],
      白山市: [
        "浑江区",
        "江源区",
        "抚松县",
        "靖宇县",
        "长白朝鲜族自治县",
        "临江市"
      ],
      松原市: [
        "宁江区",
        "前郭尔罗斯蒙古族自治县",
        "长岭县",
        "乾安县",
        "吉林松原经济开发区",
        "扶余市"
      ],
      白城市: [
        "洮北区",
        "镇赉县",
        "通榆县",
        "吉林白城经济开发区",
        "洮南市",
        "大安市"
      ],
      延边: [
        "延吉市",
        "图们市",
        "敦化市",
        "珲春市",
        "龙井市",
        "和龙市",
        "汪清县",
        "安图县"
      ]
    },
    黑龙江省: {
      哈尔滨市: [
        "道里区",
        "南岗区",
        "道外区",
        "平房区",
        "松北区",
        "香坊区",
        "呼兰区",
        "阿城区",
        "双城区",
        "依兰县",
        "方正县",
        "宾县",
        "巴彦县",
        "木兰县",
        "通河县",
        "延寿县",
        "尚志市",
        "五常市"
      ],
      齐齐哈尔市: [
        "龙沙区",
        "建华区",
        "铁锋区",
        "昂昂溪区",
        "富拉尔基区",
        "碾子山区",
        "梅里斯达斡尔族区",
        "龙江县",
        "依安县",
        "泰来县",
        "甘南县",
        "富裕县",
        "克山县",
        "克东县",
        "拜泉县",
        "讷河市"
      ],
      鸡西市: [
        "鸡冠区",
        "恒山区",
        "滴道区",
        "梨树区",
        "城子河区",
        "麻山区",
        "鸡东县",
        "虎林市",
        "密山市"
      ],
      鹤岗市: [
        "向阳区",
        "工农区",
        "南山区",
        "兴安区",
        "东山区",
        "兴山区",
        "萝北县",
        "绥滨县"
      ],
      双鸭山市: [
        "尖山区",
        "岭东区",
        "四方台区",
        "宝山区",
        "集贤县",
        "友谊县",
        "宝清县",
        "饶河县"
      ],
      大庆市: [
        "萨尔图区",
        "龙凤区",
        "让胡路区",
        "红岗区",
        "大同区",
        "肇州县",
        "肇源县",
        "林甸县",
        "杜尔伯特蒙古族自治县",
        "大庆高新技术产业开发区"
      ],
      伊春市: [
        "伊美区",
        "乌翠区",
        "友好区",
        "嘉荫县",
        "汤旺县",
        "丰林县",
        "大箐山县",
        "南岔县",
        "金林区",
        "铁力市"
      ],
      佳木斯市: [
        "向阳区",
        "前进区",
        "东风区",
        "郊区",
        "桦南县",
        "桦川县",
        "汤原县",
        "同江市",
        "富锦市",
        "抚远市"
      ],
      七台河市: [
        "新兴区",
        "桃山区",
        "茄子河区",
        "勃利县"
      ],
      牡丹江市: [
        "东安区",
        "阳明区",
        "爱民区",
        "西安区",
        "林口县",
        "绥芬河市",
        "海林市",
        "宁安市",
        "穆棱市",
        "东宁市"
      ],
      黑河市: [
        "爱辉区",
        "逊克县",
        "孙吴县",
        "北安市",
        "五大连池市",
        "嫩江市"
      ],
      绥化市: [
        "北林区",
        "望奎县",
        "兰西县",
        "青冈县",
        "庆安县",
        "明水县",
        "绥棱县",
        "安达市",
        "肇东市",
        "海伦市"
      ],
      大兴安岭: [
        "漠河市",
        "呼玛县",
        "塔河县",
        "加格达奇区",
        "松岭区",
        "新林区",
        "呼中区"
      ]
    },
    上海市: {
        上海市: [
        "黄浦区",
        "徐汇区",
        "长宁区",
        "静安区",
        "普陀区",
        "虹口区",
        "杨浦区",
        "闵行区",
        "宝山区",
        "嘉定区",
        "浦东新区",
        "金山区",
        "松江区",
        "青浦区",
        "奉贤区",
        "崇明区"
      ]
    },
    江苏省: {
      南京市: [
        "玄武区",
        "秦淮区",
        "建邺区",
        "鼓楼区",
        "浦口区",
        "栖霞区",
        "雨花台区",
        "江宁区",
        "六合区",
        "溧水区",
        "高淳区"
      ],
      无锡市: [
        "锡山区",
        "惠山区",
        "滨湖区",
        "梁溪区",
        "新吴区",
        "江阴市",
        "宜兴市"
      ],
      徐州市: [
        "鼓楼区",
        "云龙区",
        "贾汪区",
        "泉山区",
        "铜山区",
        "丰县",
        "沛县",
        "睢宁县",
        "徐州经济技术开发区",
        "新沂市",
        "邳州市"
      ],
      常州市: [
        "天宁区",
        "钟楼区",
        "新北区",
        "武进区",
        "金坛区",
        "溧阳市"
      ],
      苏州市: [
        "虎丘区",
        "吴中区",
        "相城区",
        "姑苏区",
        "吴江区",
        "苏州工业园区",
        "常熟市",
        "张家港市",
        "昆山市",
        "太仓市"
      ],
      南通市: [
        "通州区",
        "崇川区",
        "海门区",
        "如东县",
        "南通经济技术开发区",
        "启东市",
        "如皋市",
        "海安市"
      ],
      连云港市: [
        "连云区",
        "海州区",
        "赣榆区",
        "东海县",
        "灌云县",
        "灌南县",
        "连云港经济技术开发区"
      ],
      淮安市: [
        "淮安区",
        "淮阴区",
        "清江浦区",
        "洪泽区",
        "涟水县",
        "盱眙县",
        "金湖县",
        "淮安经济技术开发区"
      ],
      盐城市: [
        "亭湖区",
        "盐都区",
        "大丰区",
        "响水县",
        "滨海县",
        "阜宁县",
        "射阳县",
        "建湖县",
        "盐城经济技术开发区",
        "东台市"
      ],
      扬州市: [
        "广陵区",
        "邗江区",
        "江都区",
        "宝应县",
        "扬州经济技术开发区",
        "仪征市",
        "高邮市"
      ],
      镇江市: [
        "京口区",
        "润州区",
        "丹徒区",
        "镇江新区",
        "丹阳市",
        "扬中市",
        "句容市"
      ],
      泰州市: [
        "海陵区",
        "高港区",
        "姜堰区",
        "兴化市",
        "靖江市",
        "泰兴市"
      ],
      宿迁市: [
        "宿城区",
        "宿豫区",
        "沭阳县",
        "泗阳县",
        "泗洪县",
        "宿迁经济技术开发区"
      ]
    },
    浙江省: {
      杭州市: [
        "上城区",
        "拱墅区",
        "西湖区",
        "滨江区",
        "萧山区",
        "余杭区",
        "富阳区",
        "临安区",
        "临平区",
        "钱塘区",
        "桐庐县",
        "淳安县",
        "建德市"
      ],
      宁波市: [
        "海曙区",
        "江北区",
        "北仑区",
        "镇海区",
        "鄞州区",
        "奉化区",
        "象山县",
        "宁海县",
        "余姚市",
        "慈溪市"
      ],
      温州市: [
        "鹿城区",
        "龙湾区",
        "瓯海区",
        "洞头区",
        "永嘉县",
        "平阳县",
        "苍南县",
        "文成县",
        "泰顺县",
        "瑞安市",
        "乐清市",
        "龙港市"
      ],
      嘉兴市: [
        "南湖区",
        "秀洲区",
        "嘉善县",
        "海盐县",
        "海宁市",
        "平湖市",
        "桐乡市"
      ],
      湖州市: [
        "吴兴区",
        "南浔区",
        "德清县",
        "长兴县",
        "安吉县"
      ],
      绍兴市: [
        "越城区",
        "柯桥区",
        "上虞区",
        "新昌县",
        "诸暨市",
        "嵊州市"
      ],
      金华市: [
        "婺城区",
        "金东区",
        "武义县",
        "浦江县",
        "磐安县",
        "兰溪市",
        "义乌市",
        "东阳市",
        "永康市"
      ],
      衢州市: [
        "柯城区",
        "衢江区",
        "常山县",
        "开化县",
        "龙游县",
        "江山市"
      ],
      舟山市: [
        "定海区",
        "普陀区",
        "岱山县",
        "嵊泗县"
      ],
      台州市: [
        "椒江区",
        "黄岩区",
        "路桥区",
        "三门县",
        "天台县",
        "仙居县",
        "温岭市",
        "临海市",
        "玉环市"
      ],
      丽水市: [
        "莲都区",
        "青田县",
        "缙云县",
        "遂昌县",
        "松阳县",
        "云和县",
        "庆元县",
        "景宁畲族自治县",
        "龙泉市"
      ]
    },
    安徽省: {
      合肥市: [
        "瑶海区",
        "庐阳区",
        "蜀山区",
        "包河区",
        "长丰县",
        "肥东县",
        "肥西县",
        "庐江县",
        "合肥高新技术产业开发区",
        "合肥经济技术开发区",
        "合肥新站高新技术产业开发区",
        "巢湖市"
      ],
      芜湖市: [
        "镜湖区",
        "鸠江区",
        "弋江区",
        "湾沚区",
        "繁昌区",
        "南陵县",
        "芜湖经济技术开发区",
        "安徽芜湖三山经济开发区",
        "无为市"
      ],
      蚌埠市: [
        "龙子湖区",
        "蚌山区",
        "禹会区",
        "淮上区",
        "怀远县",
        "五河县",
        "固镇县",
        "蚌埠市高新技术开发区",
        "蚌埠市经济开发区"
      ],
      淮南市: [
        "大通区",
        "田家庵区",
        "谢家集区",
        "八公山区",
        "潘集区",
        "凤台县",
        "寿县"
      ],
      马鞍山市: [
        "花山区",
        "雨山区",
        "博望区",
        "当涂县",
        "含山县",
        "和县"
      ],
      淮北市: [
        "杜集区",
        "相山区",
        "烈山区",
        "濉溪县"
      ],
      铜陵市: [
        "铜官区",
        "义安区",
        "郊区",
        "枞阳县"
      ],
      安庆市: [
        "迎江区",
        "大观区",
        "宜秀区",
        "怀宁县",
        "太湖县",
        "宿松县",
        "望江县",
        "岳西县",
        "安徽安庆经济开发区",
        "桐城市",
        "潜山市"
      ],
      黄山市: [
        "屯溪区",
        "黄山区",
        "徽州区",
        "歙县",
        "休宁县",
        "黟县",
        "祁门县"
      ],
      滁州市: [
        "琅琊区",
        "南谯区",
        "来安县",
        "全椒县",
        "定远县",
        "凤阳县",
        "中新苏滁高新技术产业开发区",
        "滁州经济技术开发区",
        "天长市",
        "明光市"
      ],
      阜阳市: [
        "颍州区",
        "颍东区",
        "颍泉区",
        "临泉县",
        "太和县",
        "阜南县",
        "颍上县",
        "阜阳合肥现代产业园区",
        "阜阳经济技术开发区",
        "界首市"
      ],
      宿州市: [
        "埇桥区",
        "砀山县",
        "萧县",
        "灵璧县",
        "泗县",
        "宿州马鞍山现代产业园区",
        "宿州经济技术开发区"
      ],
      六安市: [
        "金安区",
        "裕安区",
        "叶集区",
        "霍邱县",
        "舒城县",
        "金寨县",
        "霍山县"
      ],
      亳州市: [
        "谯城区",
        "涡阳县",
        "蒙城县",
        "利辛县"
      ],
      池州市: [
        "贵池区",
        "东至县",
        "石台县",
        "青阳县"
      ],
      宣城市: [
        "宣州区",
        "郎溪县",
        "泾县",
        "绩溪县",
        "旌德县",
        "宣城市经济开发区",
        "宁国市",
        "广德市"
      ]
    },
    福建省: {
      福州市: [
        "鼓楼区",
        "台江区",
        "仓山区",
        "马尾区",
        "晋安区",
        "长乐区",
        "闽侯县",
        "连江县",
        "罗源县",
        "闽清县",
        "永泰县",
        "平潭县",
        "福清市"
      ],
      厦门市: [
        "思明区",
        "海沧区",
        "湖里区",
        "集美区",
        "同安区",
        "翔安区"
      ],
      莆田市: [
        "城厢区",
        "涵江区",
        "荔城区",
        "秀屿区",
        "仙游县"
      ],
      三明市: [
        "三元区",
        "沙县区",
        "明溪县",
        "清流县",
        "宁化县",
        "大田县",
        "尤溪县",
        "将乐县",
        "泰宁县",
        "建宁县",
        "永安市"
      ],
      泉州市: [
        "鲤城区",
        "丰泽区",
        "洛江区",
        "泉港区",
        "惠安县",
        "安溪县",
        "永春县",
        "德化县",
        "金门县",
        "石狮市",
        "晋江市",
        "南安市"
      ],
      漳州市: [
        "芗城区",
        "龙文区",
        "龙海区",
        "长泰区",
        "云霄县",
        "漳浦县",
        "诏安县",
        "东山县",
        "南靖县",
        "平和县",
        "华安县"
      ],
      南平市: [
        "延平区",
        "建阳区",
        "顺昌县",
        "浦城县",
        "光泽县",
        "松溪县",
        "政和县",
        "邵武市",
        "武夷山市",
        "建瓯市"
      ],
      龙岩市: [
        "新罗区",
        "永定区",
        "长汀县",
        "上杭县",
        "武平县",
        "连城县",
        "漳平市"
      ],
      宁德市: [
        "蕉城区",
        "霞浦县",
        "古田县",
        "屏南县",
        "寿宁县",
        "周宁县",
        "柘荣县",
        "福安市",
        "福鼎市"
      ]
    },
    江西省: {
      南昌市: [
        "东湖区",
        "西湖区",
        "青云谱区",
        "青山湖区",
        "新建区",
        "红谷滩区",
        "南昌县",
        "安义县",
        "进贤县"
      ],
      景德镇市: [
        "昌江区",
        "珠山区",
        "浮梁县",
        "乐平市"
      ],
      萍乡市: [
        "安源区",
        "湘东区",
        "莲花县",
        "上栗县",
        "芦溪县"
      ],
      九江市: [
        "濂溪区",
        "浔阳区",
        "柴桑区",
        "武宁县",
        "修水县",
        "永修县",
        "德安县",
        "都昌县",
        "湖口县",
        "彭泽县",
        "瑞昌市",
        "共青城市",
        "庐山市"
      ],
      新余市: [
        "渝水区",
        "分宜县"
      ],
      鹰潭市: [
        "月湖区",
        "余江区",
        "贵溪市"
      ],
      赣州市: [
        "章贡区",
        "南康区",
        "赣县区",
        "信丰县",
        "大余县",
        "上犹县",
        "崇义县",
        "安远县",
        "定南县",
        "全南县",
        "宁都县",
        "于都县",
        "兴国县",
        "会昌县",
        "寻乌县",
        "石城县",
        "瑞金市",
        "龙南市"
      ],
      吉安市: [
        "吉州区",
        "青原区",
        "吉安县",
        "吉水县",
        "峡江县",
        "新干县",
        "永丰县",
        "泰和县",
        "遂川县",
        "万安县",
        "安福县",
        "永新县",
        "井冈山市"
      ],
      宜春市: [
        "袁州区",
        "奉新县",
        "万载县",
        "上高县",
        "宜丰县",
        "靖安县",
        "铜鼓县",
        "丰城市",
        "樟树市",
        "高安市"
      ],
      抚州市: [
        "临川区",
        "东乡区",
        "南城县",
        "黎川县",
        "南丰县",
        "崇仁县",
        "乐安县",
        "宜黄县",
        "金溪县",
        "资溪县",
        "广昌县"
      ],
      上饶市: [
        "信州区",
        "广丰区",
        "广信区",
        "玉山县",
        "铅山县",
        "横峰县",
        "弋阳县",
        "余干县",
        "鄱阳县",
        "万年县",
        "婺源县",
        "德兴市"
      ]
    },
    山东省: {
      济南市: [
        "历下区",
        "市中区",
        "槐荫区",
        "天桥区",
        "历城区",
        "长清区",
        "章丘区",
        "济阳区",
        "莱芜区",
        "钢城区",
        "平阴县",
        "商河县",
        "济南高新技术产业开发区"
      ],
      青岛市: [
        "市南区",
        "市北区",
        "黄岛区",
        "崂山区",
        "李沧区",
        "城阳区",
        "即墨区",
        "胶州市",
        "平度市",
        "莱西市"
      ],
      淄博市: [
        "淄川区",
        "张店区",
        "博山区",
        "临淄区",
        "周村区",
        "桓台县",
        "高青县",
        "沂源县"
      ],
      枣庄市: [
        "市中区",
        "薛城区",
        "峄城区",
        "台儿庄区",
        "山亭区",
        "滕州市"
      ],
      东营市: [
        "东营区",
        "河口区",
        "垦利区",
        "利津县",
        "广饶县",
        "东营经济技术开发区",
        "东营港经济开发区"
      ],
      烟台市: [
        "芝罘区",
        "福山区",
        "牟平区",
        "莱山区",
        "蓬莱区",
        "烟台高新技术产业开发区",
        "烟台经济技术开发区",
        "龙口市",
        "莱阳市",
        "莱州市",
        "招远市",
        "栖霞市",
        "海阳市"
      ],
      潍坊市: [
        "潍城区",
        "寒亭区",
        "坊子区",
        "奎文区",
        "临朐县",
        "昌乐县",
        "潍坊滨海经济技术开发区",
        "青州市",
        "诸城市",
        "寿光市",
        "安丘市",
        "高密市",
        "昌邑市"
      ],
      济宁市: [
        "任城区",
        "兖州区",
        "微山县",
        "鱼台县",
        "金乡县",
        "嘉祥县",
        "汶上县",
        "泗水县",
        "梁山县",
        "济宁高新技术产业开发区",
        "曲阜市",
        "邹城市"
      ],
      泰安市: [
        "泰山区",
        "岱岳区",
        "宁阳县",
        "东平县",
        "新泰市",
        "肥城市"
      ],
      威海市: [
        "环翠区",
        "文登区",
        "威海火炬高技术产业开发区",
        "威海经济技术开发区",
        "威海临港经济技术开发区",
        "荣成市",
        "乳山市"
      ],
      日照市: [
        "东港区",
        "岚山区",
        "五莲县",
        "莒县",
        "日照经济技术开发区"
      ],
      临沂市: [
        "兰山区",
        "罗庄区",
        "河东区",
        "沂南县",
        "郯城县",
        "沂水县",
        "兰陵县",
        "费县",
        "平邑县",
        "莒南县",
        "蒙阴县",
        "临沭县",
        "临沂高新技术产业开发区"
      ],
      德州市: [
        "德城区",
        "陵城区",
        "宁津县",
        "庆云县",
        "临邑县",
        "齐河县",
        "平原县",
        "夏津县",
        "武城县",
        "德州天衢新区",
        "乐陵市",
        "禹城市"
      ],
      聊城市: [
        "东昌府区",
        "茌平区",
        "阳谷县",
        "莘县",
        "东阿县",
        "冠县",
        "高唐县",
        "临清市"
      ],
      滨州市: [
        "滨城区",
        "沾化区",
        "惠民县",
        "阳信县",
        "无棣县",
        "博兴县",
        "邹平市"
      ],
      菏泽市: [
        "牡丹区",
        "定陶区",
        "曹县",
        "单县",
        "成武县",
        "巨野县",
        "郓城县",
        "鄄城县",
        "东明县",
        "菏泽经济技术开发区",
        "菏泽高新技术开发区"
      ]
    },
    河南省: {
      郑州市: [
        "中原区",
        "二七区",
        "管城回族区",
        "金水区",
        "上街区",
        "惠济区",
        "中牟县",
        "郑州经济技术开发区",
        "郑州高新技术产业开发区",
        "郑州航空港经济综合实验区",
        "巩义市",
        "荥阳市",
        "新密市",
        "新郑市",
        "登封市"
      ],
      开封市: [
        "龙亭区",
        "顺河回族区",
        "鼓楼区",
        "禹王台区",
        "祥符区",
        "杞县",
        "通许县",
        "尉氏县",
        "兰考县"
      ],
      洛阳市: [
        "老城区",
        "西工区",
        "瀍河回族区",
        "涧西区",
        "偃师区",
        "孟津区",
        "洛龙区",
        "新安县",
        "栾川县",
        "嵩县",
        "汝阳县",
        "宜阳县",
        "洛宁县",
        "伊川县",
        "洛阳高新技术产业开发区"
      ],
      平顶山市: [
        "新华区",
        "卫东区",
        "石龙区",
        "湛河区",
        "宝丰县",
        "叶县",
        "鲁山县",
        "郏县",
        "平顶山高新技术产业开发区",
        "平顶山市城乡一体化示范区",
        "舞钢市",
        "汝州市"
      ],
      安阳市: [
        "文峰区",
        "北关区",
        "殷都区",
        "龙安区",
        "安阳县",
        "汤阴县",
        "滑县",
        "内黄县",
        "安阳高新技术产业开发区",
        "林州市"
      ],
      鹤壁市: [
        "鹤山区",
        "山城区",
        "淇滨区",
        "浚县",
        "淇县",
        "鹤壁经济技术开发区"
      ],
      新乡市: [
        "红旗区",
        "卫滨区",
        "凤泉区",
        "牧野区",
        "新乡县",
        "获嘉县",
        "原阳县",
        "延津县",
        "封丘县",
        "新乡高新技术产业开发区",
        "新乡经济技术开发区",
        "新乡市平原城乡一体化示范区",
        "卫辉市",
        "辉县市",
        "长垣市"
      ],
      焦作市: [
        "解放区",
        "中站区",
        "马村区",
        "山阳区",
        "修武县",
        "博爱县",
        "武陟县",
        "温县",
        "焦作城乡一体化示范区",
        "沁阳市",
        "孟州市"
      ],
      濮阳市: [
        "华龙区",
        "清丰县",
        "南乐县",
        "范县",
        "台前县",
        "濮阳县",
        "河南濮阳工业园区",
        "濮阳经济技术开发区"
      ],
      许昌市: [
        "魏都区",
        "建安区",
        "鄢陵县",
        "襄城县",
        "许昌经济技术开发区",
        "禹州市",
        "长葛市"
      ],
      漯河市: [
        "源汇区",
        "郾城区",
        "召陵区",
        "舞阳县",
        "临颍县",
        "漯河经济技术开发区"
      ],
      三门峡市: [
        "湖滨区",
        "陕州区",
        "渑池县",
        "卢氏县",
        "河南三门峡经济开发区",
        "义马市",
        "灵宝市"
      ],
      南阳市: [
        "宛城区",
        "卧龙区",
        "南召县",
        "方城县",
        "西峡县",
        "镇平县",
        "内乡县",
        "淅川县",
        "社旗县",
        "唐河县",
        "新野县",
        "桐柏县",
        "南阳高新技术产业开发区",
        "南阳市城乡一体化示范区",
        "邓州市"
      ],
      商丘市: [
        "梁园区",
        "睢阳区",
        "民权县",
        "睢县",
        "宁陵县",
        "柘城县",
        "虞城县",
        "夏邑县",
        "豫东综合物流产业聚集区",
        "河南商丘经济开发区",
        "永城市"
      ],
      信阳市: [
        "浉河区",
        "平桥区",
        "罗山县",
        "光山县",
        "新县",
        "商城县",
        "固始县",
        "潢川县",
        "淮滨县",
        "息县",
        "信阳高新技术产业开发区"
      ],
      周口市: [
        "川汇区",
        "淮阳区",
        "扶沟县",
        "西华县",
        "商水县",
        "沈丘县",
        "郸城县",
        "太康县",
        "鹿邑县",
        "周口临港开发区",
        "项城市"
      ],
      驻马店市: [
        "驿城区",
        "西平县",
        "上蔡县",
        "平舆县",
        "正阳县",
        "确山县",
        "泌阳县",
        "汝南县",
        "遂平县",
        "新蔡县",
        "河南驻马店经济开发区"
      ],
      济源市: [
       "济源市"
      ]
    },
    湖北省: {
      武汉市: [
        "江岸区",
        "江汉区",
        "硚口区",
        "汉阳区",
        "武昌区",
        "青山区",
        "洪山区",
        "东西湖区",
        "汉南区",
        "蔡甸区",
        "江夏区",
        "黄陂区",
        "新洲区"
      ],
      黄石市: [
        "黄石港区",
        "西塞山区",
        "下陆区",
        "铁山区",
        "阳新县",
        "大冶市"
      ],
      十堰市: [
        "茅箭区",
        "张湾区",
        "郧阳区",
        "郧西县",
        "竹山县",
        "竹溪县",
        "房县",
        "丹江口市"
      ],
      宜昌市: [
        "西陵区",
        "伍家岗区",
        "点军区",
        "猇亭区",
        "夷陵区",
        "远安县",
        "兴山县",
        "秭归县",
        "长阳土家族自治县",
        "五峰土家族自治县",
        "宜都市",
        "当阳市",
        "枝江市"
      ],
      襄阳市: [
        "襄城区",
        "樊城区",
        "襄州区",
        "南漳县",
        "谷城县",
        "保康县",
        "老河口市",
        "枣阳市",
        "宜城市"
      ],
      鄂州市: [
        "梁子湖区",
        "华容区",
        "鄂城区"
      ],
      荆门市: [
        "东宝区",
        "掇刀区",
        "沙洋县",
        "钟祥市",
        "京山市"
      ],
      孝感市: [
        "孝南区",
        "孝昌县",
        "大悟县",
        "云梦县",
        "应城市",
        "安陆市",
        "汉川市"
      ],
      荆州市: [
        "沙市区",
        "荆州区",
        "公安县",
        "江陵县",
        "荆州经济技术开发区",
        "石首市",
        "洪湖市",
        "松滋市",
        "监利市"
      ],
      黄冈市: [
        "黄州区",
        "团风县",
        "红安县",
        "罗田县",
        "英山县",
        "浠水县",
        "蕲春县",
        "黄梅县",
        "龙感湖管理区",
        "麻城市",
        "武穴市"
      ],
      咸宁市: [
        "咸安区",
        "嘉鱼县",
        "通城县",
        "崇阳县",
        "通山县",
        "赤壁市"
      ],
      随州市: [
        "曾都区",
        "随县",
        "广水市"
      ],
      恩施: [
        "恩施市",
        "利川市",
        "建始县",
        "巴东县",
        "宣恩县",
        "咸丰县",
        "来凤县",
        "鹤峰县"
      ],
    
      仙桃市: [
        "仙桃市",
      ],
      潜江市: [
        "潜江市",
      ],
      天门市: [
        "天门市",
      ],
      神农架: [
        "神农架",
      ],
    },
    湖南省: {
      长沙市: [
        "芙蓉区",
        "天心区",
        "岳麓区",
        "开福区",
        "雨花区",
        "望城区",
        "长沙县",
        "浏阳市",
        "宁乡市"
      ],
      株洲市: [
        "荷塘区",
        "芦淞区",
        "石峰区",
        "天元区",
        "渌口区",
        "攸县",
        "茶陵县",
        "炎陵县",
        "醴陵市"
      ],
      湘潭市: [
        "雨湖区",
        "岳塘区",
        "湘潭县",
        "湖南湘潭高新技术产业园区",
        "湘潭昭山示范区",
        "湘潭九华示范区",
        "湘乡市",
        "韶山市"
      ],
      衡阳市: [
        "珠晖区",
        "雁峰区",
        "石鼓区",
        "蒸湘区",
        "南岳区",
        "衡阳县",
        "衡南县",
        "衡山县",
        "衡东县",
        "祁东县",
        "湖南衡阳松木经济开发区",
        "湖南衡阳高新技术产业园区",
        "耒阳市",
        "常宁市"
      ],
      邵阳市: [
        "双清区",
        "大祥区",
        "北塔区",
        "新邵县",
        "邵阳县",
        "隆回县",
        "洞口县",
        "绥宁县",
        "新宁县",
        "城步苗族自治县",
        "武冈市",
        "邵东市"
      ],
      岳阳市: [
        "岳阳楼区",
        "云溪区",
        "君山区",
        "岳阳县",
        "华容县",
        "湘阴县",
        "平江县",
        "岳阳市屈原管理区",
        "汨罗市",
        "临湘市"
      ],
      常德市: [
        "武陵区",
        "鼎城区",
        "安乡县",
        "汉寿县",
        "澧县",
        "临澧县",
        "桃源县",
        "石门县",
        "常德市西洞庭管理区",
        "津市市"
      ],
      张家界市: [
        "永定区",
        "武陵源区",
        "慈利县",
        "桑植县"
      ],
      益阳市: [
        "资阳区",
        "赫山区",
        "南县",
        "桃江县",
        "安化县",
        "益阳市大通湖管理区",
        "湖南益阳高新技术产业园区",
        "沅江市"
      ],
      郴州市: [
        "北湖区",
        "苏仙区",
        "桂阳县",
        "宜章县",
        "永兴县",
        "嘉禾县",
        "临武县",
        "汝城县",
        "桂东县",
        "安仁县",
        "资兴市"
      ],
      永州市: [
        "零陵区",
        "冷水滩区",
        "东安县",
        "双牌县",
        "道县",
        "江永县",
        "宁远县",
        "蓝山县",
        "新田县",
        "江华瑶族自治县",
        "永州经济技术开发区",
        "永州市回龙圩管理区",
        "祁阳市"
      ],
      怀化市: [
        "鹤城区",
        "中方县",
        "沅陵县",
        "辰溪县",
        "溆浦县",
        "会同县",
        "麻阳苗族自治县",
        "新晃侗族自治县",
        "芷江侗族自治县",
        "靖州苗族侗族自治县",
        "通道侗族自治县",
        "怀化市洪江管理区",
        "洪江市"
      ],
      娄底市: [
        "娄星区",
        "双峰县",
        "新化县",
        "冷水江市",
        "涟源市"
      ],
      湘西: [
        "吉首市",
        "泸溪县",
        "凤凰县",
        "花垣县",
        "保靖县",
        "古丈县",
        "永顺县",
        "龙山县"
      ]
    },
    广东省: {
      广州市: [
        "荔湾区",
        "越秀区",
        "海珠区",
        "天河区",
        "白云区",
        "黄埔区",
        "番禺区",
        "花都区",
        "南沙区",
        "从化区",
        "增城区"
      ],
      韶关市: [
        "武江区",
        "浈江区",
        "曲江区",
        "始兴县",
        "仁化县",
        "翁源县",
        "乳源瑶族自治县",
        "新丰县",
        "乐昌市",
        "南雄市"
      ],
      深圳市: [
        "罗湖区",
        "福田区",
        "南山区",
        "宝安区",
        "龙岗区",
        "盐田区",
        "龙华区",
        "坪山区",
        "光明区"
      ],
      珠海市: [
        "香洲区",
        "斗门区",
        "金湾区"
      ],
      汕头市: [
        "龙湖区",
        "金平区",
        "濠江区",
        "潮阳区",
        "潮南区",
        "澄海区",
        "南澳县"
      ],
      佛山市: [
        "禅城区",
        "南海区",
        "顺德区",
        "三水区",
        "高明区"
      ],
      江门市: [
        "蓬江区",
        "江海区",
        "新会区",
        "台山市",
        "开平市",
        "鹤山市",
        "恩平市"
      ],
      湛江市: [
        "赤坎区",
        "霞山区",
        "坡头区",
        "麻章区",
        "遂溪县",
        "徐闻县",
        "廉江市",
        "雷州市",
        "吴川市"
      ],
      茂名市: [
        "茂南区",
        "电白区",
        "高州市",
        "化州市",
        "信宜市"
      ],
      肇庆市: [
        "端州区",
        "鼎湖区",
        "高要区",
        "广宁县",
        "怀集县",
        "封开县",
        "德庆县",
        "四会市"
      ],
      惠州市: [
        "惠城区",
        "惠阳区",
        "博罗县",
        "惠东县",
        "龙门县"
      ],
      梅州市: [
        "梅江区",
        "梅县区",
        "大埔县",
        "丰顺县",
        "五华县",
        "平远县",
        "蕉岭县",
        "兴宁市"
      ],
      汕尾市: [
        "城区",
        "海丰县",
        "陆河县",
        "陆丰市"
      ],
      河源市: [
        "源城区",
        "紫金县",
        "龙川县",
        "连平县",
        "和平县",
        "东源县"
      ],
      阳江市: [
        "江城区",
        "阳东区",
        "阳西县",
        "阳春市"
      ],
      清远市: [
        "清城区",
        "清新区",
        "佛冈县",
        "阳山县",
        "连山壮族瑶族自治县",
        "连南瑶族自治县",
        "英德市",
        "连州市"
      ],
      东莞市: [
        "东城街道",
        "南城街道",
        "万江街道",
        "莞城街道",
        "石碣镇",
        "石龙镇",
        "茶山镇",
        "石排镇",
        "企石镇",
        "横沥镇",
        "桥头镇",
        "谢岗镇",
        "东坑镇",
        "常平镇",
        "寮步镇",
        "樟木头镇",
        "大朗镇",
        "黄江镇",
        "清溪镇",
        "塘厦镇",
        "凤岗镇",
        "大岭山镇",
        "长安镇",
        "虎门镇",
        "厚街镇",
        "沙田镇",
        "道滘镇",
        "洪梅镇",
        "麻涌镇",
        "望牛墩镇",
        "中堂镇",
        "高埗镇",
        "松山湖",
        "东莞港",
        "东莞生态园",
        "东莞滨海湾新区"
      ],
      中山市: [
        "石岐街道",
        "东区街道",
        "中山港街道",
        "西区街道",
        "南区街道",
        "五桂山街道",
        "民众街道",
        "南朗街道",
        "黄圃镇",
        "东凤镇",
        "古镇镇",
        "沙溪镇",
        "坦洲镇",
        "港口镇",
        "三角镇",
        "横栏镇",
        "南头镇",
        "阜沙镇",
        "三乡镇",
        "板芙镇",
        "大涌镇",
        "神湾镇",
        "小榄镇"
      ],
      潮州市: [
        "湘桥区",
        "潮安区",
        "饶平县"
      ],
      揭阳市: [
        "榕城区",
        "揭东区",
        "揭西县",
        "惠来县",
        "普宁市"
      ],
      云浮市: [
        "云城区",
        "云安区",
        "新兴县",
        "郁南县",
        "罗定市"
      ]
    },
    广西: {
      南宁市: [
        "兴宁区",
        "青秀区",
        "江南区",
        "西乡塘区",
        "良庆区",
        "邕宁区",
        "武鸣区",
        "隆安县",
        "马山县",
        "上林县",
        "宾阳县",
        "横州市"
      ],
      柳州市: [
        "城中区",
        "鱼峰区",
        "柳南区",
        "柳北区",
        "柳江区",
        "柳城县",
        "鹿寨县",
        "融安县",
        "融水苗族自治县",
        "三江侗族自治县"
      ],
      桂林市: [
        "秀峰区",
        "叠彩区",
        "象山区",
        "七星区",
        "雁山区",
        "临桂区",
        "阳朔县",
        "灵川县",
        "全州县",
        "兴安县",
        "永福县",
        "灌阳县",
        "龙胜各族自治县",
        "资源县",
        "平乐县",
        "恭城瑶族自治县",
        "荔浦市"
      ],
      梧州市: [
        "万秀区",
        "长洲区",
        "龙圩区",
        "苍梧县",
        "藤县",
        "蒙山县",
        "岑溪市"
      ],
      北海市: [
        "海城区",
        "银海区",
        "铁山港区",
        "合浦县"
      ],
      防城港市: [
        "港口区",
        "防城区",
        "上思县",
        "东兴市"
      ],
      钦州市: [
        "钦南区",
        "钦北区",
        "灵山县",
        "浦北县"
      ],
      贵港市: [
        "港北区",
        "港南区",
        "覃塘区",
        "平南县",
        "桂平市"
      ],
      玉林市: [
        "玉州区",
        "福绵区",
        "容县",
        "陆川县",
        "博白县",
        "兴业县",
        "北流市"
      ],
      百色市: [
        "右江区",
        "田阳区",
        "田东县",
        "德保县",
        "那坡县",
        "凌云县",
        "乐业县",
        "田林县",
        "西林县",
        "隆林各族自治县",
        "靖西市",
        "平果市"
      ],
      贺州市: [
        "八步区",
        "平桂区",
        "昭平县",
        "钟山县",
        "富川瑶族自治县"
      ],
      河池市: [
        "金城江区",
        "宜州区",
        "南丹县",
        "天峨县",
        "凤山县",
        "东兰县",
        "罗城仫佬族自治县",
        "环江毛南族自治县",
        "巴马瑶族自治县",
        "都安瑶族自治县",
        "大化瑶族自治县"
      ],
      来宾市: [
        "兴宾区",
        "忻城县",
        "象州县",
        "武宣县",
        "金秀瑶族自治县",
        "合山市"
      ],
      崇左市: [
        "江州区",
        "扶绥县",
        "宁明县",
        "龙州县",
        "大新县",
        "天等县",
        "凭祥市"
      ]
    },
    海南省: {
      海口市: [
        "秀英区",
        "龙华区",
        "琼山区",
        "美兰区"
      ],
      三亚市: [
        "海棠区",
        "吉阳区",
        "天涯区",
        "崖州区"
      ],
      三沙市: [
        "西沙群岛",
        "南沙群岛",
        "中沙群岛的岛礁及其海域"
      ],
      儋州市: [
        "那大镇",
        "和庆镇",
        "南丰镇",
        "大成镇",
        "雅星镇",
        "兰洋镇",
        "光村镇",
        "木棠镇",
        "海头镇",
        "峨蔓镇",
        "王五镇",
        "白马井镇",
        "中和镇",
        "排浦镇",
        "东成镇",
        "新州镇",
        "洋浦经济开发区",
        "华南热作学院"
      ],
    
      五指山市:[
        "五指山市"
      ],
      琼海市:[
        "琼海市"
      ],
      文昌市:[
        "文昌市"
      ],
      万宁市:[
        "万宁市"
      ],
      东方市:[
        "东方市"
      ],
      定安县:[
        "定安县"
      ],
      屯昌县:[
        "屯昌县"
      ],
      澄迈县:[
        "澄迈县"
      ],
      临高县:[
        "临高县"
      ],
      白沙县:[
        "白沙县"
      ],
      昌江县:[
        "昌江县"
      ],
      乐东县:[
        "乐东县"
      ],
      陵水县:[
        "陵水县"
      ],
      保亭县:[
        "保亭县"
      ],
      琼中县:[
        "琼中县"
      ],
    },
    重庆市: {
        重庆市: [
        "万州区",
        "涪陵区",
        "渝中区",
        "大渡口区",
        "江北区",
        "沙坪坝区",
        "九龙坡区",
        "南岸区",
        "北碚区",
        "綦江区",
        "大足区",
        "渝北区",
        "巴南区",
        "黔江区",
        "长寿区",
        "江津区",
        "合川区",
        "永川区",
        "南川区",
        "璧山区",
        "铜梁区",
        "潼南区",
        "荣昌区",
        "开州区",
        "梁平区",
        "武隆区",
        "城口县",
        "丰都县",
        "垫江县",
        "忠县",
        "云阳县",
        "奉节县",
        "巫山县",
        "巫溪县",
        "石柱县",
        "秀山县",
        "酉阳县",
        "彭水县",
      ],
    },
    四川省: {
      成都市: [
        "锦江区",
        "青羊区",
        "金牛区",
        "武侯区",
        "成华区",
        "龙泉驿区",
        "青白江区",
        "新都区",
        "温江区",
        "双流区",
        "郫都区",
        "新津区",
        "金堂县",
        "大邑县",
        "蒲江县",
        "都江堰市",
        "彭州市",
        "邛崃市",
        "崇州市",
        "简阳市"
      ],
      自贡市: [
        "自流井区",
        "贡井区",
        "大安区",
        "沿滩区",
        "荣县",
        "富顺县"
      ],
      攀枝花市: [
        "东区",
        "西区",
        "仁和区",
        "米易县",
        "盐边县"
      ],
      泸州市: [
        "江阳区",
        "纳溪区",
        "龙马潭区",
        "泸县",
        "合江县",
        "叙永县",
        "古蔺县"
      ],
      德阳市: [
        "旌阳区",
        "罗江区",
        "中江县",
        "广汉市",
        "什邡市",
        "绵竹市"
      ],
      绵阳市: [
        "涪城区",
        "游仙区",
        "安州区",
        "三台县",
        "盐亭县",
        "梓潼县",
        "北川羌族自治县",
        "平武县",
        "江油市"
      ],
      广元市: [
        "利州区",
        "昭化区",
        "朝天区",
        "旺苍县",
        "青川县",
        "剑阁县",
        "苍溪县"
      ],
      遂宁市: [
        "船山区",
        "安居区",
        "蓬溪县",
        "大英县",
        "射洪市"
      ],
      内江市: [
        "市中区",
        "东兴区",
        "威远县",
        "资中县",
        "隆昌市"
      ],
      乐山市: [
        "市中区",
        "沙湾区",
        "五通桥区",
        "金口河区",
        "犍为县",
        "井研县",
        "夹江县",
        "沐川县",
        "峨边彝族自治县",
        "马边彝族自治县",
        "峨眉山市"
      ],
      南充市: [
        "顺庆区",
        "高坪区",
        "嘉陵区",
        "南部县",
        "营山县",
        "蓬安县",
        "仪陇县",
        "西充县",
        "阆中市"
      ],
      眉山市: [
        "东坡区",
        "彭山区",
        "仁寿县",
        "洪雅县",
        "丹棱县",
        "青神县"
      ],
      宜宾市: [
        "翠屏区",
        "南溪区",
        "叙州区",
        "江安县",
        "长宁县",
        "高县",
        "珙县",
        "筠连县",
        "兴文县",
        "屏山县"
      ],
      广安市: [
        "广安区",
        "前锋区",
        "岳池县",
        "武胜县",
        "邻水县",
        "华蓥市"
      ],
      达州市: [
        "通川区",
        "达川区",
        "宣汉县",
        "开江县",
        "大竹县",
        "渠县",
        "万源市"
      ],
      雅安市: [
        "雨城区",
        "名山区",
        "荥经县",
        "汉源县",
        "石棉县",
        "天全县",
        "芦山县",
        "宝兴县"
      ],
      巴中市: [
        "巴州区",
        "恩阳区",
        "通江县",
        "南江县",
        "平昌县"
      ],
      资阳市: [
        "雁江区",
        "安岳县",
        "乐至县"
      ],
      阿坝: [
        "马尔康市",
        "汶川县",
        "理县",
        "茂县",
        "松潘县",
        "九寨沟县",
        "金川县",
        "小金县",
        "黑水县",
        "壤塘县",
        "阿坝县",
        "若尔盖县",
        "红原县"
      ],
      甘孜: [
        "康定市",
        "泸定县",
        "丹巴县",
        "九龙县",
        "雅江县",
        "道孚县",
        "炉霍县",
        "甘孜县",
        "新龙县",
        "德格县",
        "白玉县",
        "石渠县",
        "色达县",
        "理塘县",
        "巴塘县",
        "乡城县",
        "稻城县",
        "得荣县"
      ],
      凉山: [
        "西昌市",
        "会理市",
        "木里藏族自治县",
        "盐源县",
        "德昌县",
        "会东县",
        "宁南县",
        "普格县",
        "布拖县",
        "金阳县",
        "昭觉县",
        "喜德县",
        "冕宁县",
        "越西县",
        "甘洛县",
        "美姑县",
        "雷波县"
      ]
    },
    贵州省: {
      贵阳市: [
        "南明区",
        "云岩区",
        "花溪区",
        "乌当区",
        "白云区",
        "观山湖区",
        "开阳县",
        "息烽县",
        "修文县",
        "清镇市"
      ],
      六盘水市: [
        "钟山区",
        "六枝特区",
        "水城区",
        "盘州市"
      ],
      遵义市: [
        "红花岗区",
        "汇川区",
        "播州区",
        "桐梓县",
        "绥阳县",
        "正安县",
        "道真仡佬族苗族自治县",
        "务川仡佬族苗族自治县",
        "凤冈县",
        "湄潭县",
        "余庆县",
        "习水县",
        "赤水市",
        "仁怀市"
      ],
      安顺市: [
        "西秀区",
        "平坝区",
        "普定县",
        "镇宁布依族苗族自治县",
        "关岭布依族苗族自治县",
        "紫云苗族布依族自治县"
      ],
      毕节市: [
        "七星关区",
        "大方县",
        "金沙县",
        "织金县",
        "纳雍县",
        "威宁彝族回族苗族自治县",
        "赫章县",
        "黔西市"
      ],
      铜仁市: [
        "碧江区",
        "万山区",
        "江口县",
        "玉屏侗族自治县",
        "石阡县",
        "思南县",
        "印江土家族苗族自治县",
        "德江县",
        "沿河土家族自治县",
        "松桃苗族自治县"
      ],
      黔西南: [
        "兴义市",
        "兴仁市",
        "普安县",
        "晴隆县",
        "贞丰县",
        "望谟县",
        "册亨县",
        "安龙县"
      ],
      黔东南: [
        "凯里市",
        "黄平县",
        "施秉县",
        "三穗县",
        "镇远县",
        "岑巩县",
        "天柱县",
        "锦屏县",
        "剑河县",
        "台江县",
        "黎平县",
        "榕江县",
        "从江县",
        "雷山县",
        "麻江县",
        "丹寨县"
      ],
      黔南: [
        "都匀市",
        "福泉市",
        "荔波县",
        "贵定县",
        "瓮安县",
        "独山县",
        "平塘县",
        "罗甸县",
        "长顺县",
        "龙里县",
        "惠水县",
        "三都水族自治县"
      ]
    },
    云南省: {
      昆明市: [
        "五华区",
        "盘龙区",
        "官渡区",
        "西山区",
        "东川区",
        "呈贡区",
        "晋宁区",
        "富民县",
        "宜良县",
        "石林彝族自治县",
        "嵩明县",
        "禄劝彝族苗族自治县",
        "寻甸回族彝族自治县",
        "安宁市"
      ],
      曲靖市: [
        "麒麟区",
        "沾益区",
        "马龙区",
        "陆良县",
        "师宗县",
        "罗平县",
        "富源县",
        "会泽县",
        "宣威市"
      ],
      玉溪市: [
        "红塔区",
        "江川区",
        "通海县",
        "华宁县",
        "易门县",
        "峨山彝族自治县",
        "新平彝族傣族自治县",
        "元江哈尼族彝族傣族自治县",
        "澄江市"
      ],
      保山市: [
        "隆阳区",
        "施甸县",
        "龙陵县",
        "昌宁县",
        "腾冲市"
      ],
      昭通市: [
        "昭阳区",
        "鲁甸县",
        "巧家县",
        "盐津县",
        "大关县",
        "永善县",
        "绥江县",
        "镇雄县",
        "彝良县",
        "威信县",
        "水富市"
      ],
      丽江市: [
        "古城区",
        "玉龙纳西族自治县",
        "永胜县",
        "华坪县",
        "宁蒗彝族自治县"
      ],
      普洱市: [
        "思茅区",
        "宁洱哈尼族彝族自治县",
        "墨江哈尼族自治县",
        "景东彝族自治县",
        "景谷傣族彝族自治县",
        "镇沅彝族哈尼族拉祜族自治县",
        "江城哈尼族彝族自治县",
        "孟连傣族拉祜族佤族自治县",
        "澜沧拉祜族自治县",
        "西盟佤族自治县"
      ],
      临沧市: [
        "临翔区",
        "凤庆县",
        "云县",
        "永德县",
        "镇康县",
        "双江拉祜族佤族布朗族傣族自治县",
        "耿马傣族佤族自治县",
        "沧源佤族自治县"
      ],
      楚雄: [
        "楚雄市",
        "禄丰市",
        "双柏县",
        "牟定县",
        "南华县",
        "姚安县",
        "大姚县",
        "永仁县",
        "元谋县",
        "武定县"
      ],
      红河: [
        "个旧市",
        "开远市",
        "蒙自市",
        "弥勒市",
        "屏边苗族自治县",
        "建水县",
        "石屏县",
        "泸西县",
        "元阳县",
        "红河县",
        "金平苗族瑶族傣族自治县",
        "绿春县",
        "河口瑶族自治县"
      ],
      文山: [
        "文山市",
        "砚山县",
        "西畴县",
        "麻栗坡县",
        "马关县",
        "丘北县",
        "广南县",
        "富宁县"
      ],
      西双版纳: [
        "景洪市",
        "勐海县",
        "勐腊县"
      ],
      大理: [
        "大理市",
        "漾濞彝族自治县",
        "祥云县",
        "宾川县",
        "弥渡县",
        "南涧彝族自治县",
        "巍山彝族回族自治县",
        "永平县",
        "云龙县",
        "洱源县",
        "剑川县",
        "鹤庆县"
      ],
      德宏: [
        "瑞丽市",
        "芒市",
        "梁河县",
        "盈江县",
        "陇川县"
      ],
      怒江: [
        "泸水市",
        "福贡县",
        "贡山独龙族怒族自治县",
        "兰坪白族普米族自治县"
      ],
      迪庆: [
        "香格里拉市",
        "德钦县",
        "维西傈僳族自治县"
      ]
    },
    西藏: {
      拉萨市: [
        "城关区",
        "堆龙德庆区",
        "达孜区",
        "林周县",
        "当雄县",
        "尼木县",
        "曲水县",
        "墨竹工卡县",
        "格尔木藏青工业园区",
        "拉萨经济技术开发区",
        "西藏文化旅游创意园区",
        "达孜工业园区"
      ],
      日喀则市: [
        "桑珠孜区",
        "南木林县",
        "江孜县",
        "定日县",
        "萨迦县",
        "拉孜县",
        "昂仁县",
        "谢通门县",
        "白朗县",
        "仁布县",
        "康马县",
        "定结县",
        "仲巴县",
        "亚东县",
        "吉隆县",
        "聂拉木县",
        "萨嘎县",
        "岗巴县"
      ],
      昌都市: [
        "卡若区",
        "江达县",
        "贡觉县",
        "类乌齐县",
        "丁青县",
        "察雅县",
        "八宿县",
        "左贡县",
        "芒康县",
        "洛隆县",
        "边坝县"
      ],
      林芝市: [
        "巴宜区",
        "工布江达县",
        "墨脱县",
        "波密县",
        "察隅县",
        "朗县",
        "米林市"
      ],
      山南市: [
        "乃东区",
        "扎囊县",
        "贡嘎县",
        "桑日县",
        "琼结县",
        "曲松县",
        "措美县",
        "洛扎县",
        "加查县",
        "隆子县",
        "浪卡子县",
        "错那市"
      ],
      那曲市: [
        "色尼区",
        "嘉黎县",
        "比如县",
        "聂荣县",
        "安多县",
        "申扎县",
        "索县",
        "班戈县",
        "巴青县",
        "尼玛县",
        "双湖县"
      ],
      阿里: [
        "普兰县",
        "札达县",
        "噶尔县",
        "日土县",
        "革吉县",
        "改则县",
        "措勤县"
      ]
    },
    陕西省: {
      西安市: [
        "新城区",
        "碑林区",
        "莲湖区",
        "灞桥区",
        "未央区",
        "雁塔区",
        "阎良区",
        "临潼区",
        "长安区",
        "高陵区",
        "鄠邑区",
        "蓝田县",
        "周至县"
      ],
      铜川市: [
        "王益区",
        "印台区",
        "耀州区",
        "宜君县"
      ],
      宝鸡市: [
        "渭滨区",
        "金台区",
        "陈仓区",
        "凤翔区",
        "岐山县",
        "扶风县",
        "眉县",
        "陇县",
        "千阳县",
        "麟游县",
        "凤县",
        "太白县"
      ],
      咸阳市: [
        "秦都区",
        "杨陵区",
        "渭城区",
        "三原县",
        "泾阳县",
        "乾县",
        "礼泉县",
        "永寿县",
        "长武县",
        "旬邑县",
        "淳化县",
        "武功县",
        "兴平市",
        "彬州市"
      ],
      渭南市: [
        "临渭区",
        "华州区",
        "潼关县",
        "大荔县",
        "合阳县",
        "澄城县",
        "蒲城县",
        "白水县",
        "富平县",
        "韩城市",
        "华阴市"
      ],
      延安市: [
        "宝塔区",
        "安塞区",
        "延长县",
        "延川县",
        "志丹县",
        "吴起县",
        "甘泉县",
        "富县",
        "洛川县",
        "宜川县",
        "黄龙县",
        "黄陵县",
        "子长市"
      ],
      汉中市: [
        "汉台区",
        "南郑区",
        "城固县",
        "洋县",
        "西乡县",
        "勉县",
        "宁强县",
        "略阳县",
        "镇巴县",
        "留坝县",
        "佛坪县"
      ],
      榆林市: [
        "榆阳区",
        "横山区",
        "府谷县",
        "靖边县",
        "定边县",
        "绥德县",
        "米脂县",
        "佳县",
        "吴堡县",
        "清涧县",
        "子洲县",
        "神木市"
      ],
      安康市: [
        "汉滨区",
        "汉阴县",
        "石泉县",
        "宁陕县",
        "紫阳县",
        "岚皋县",
        "平利县",
        "镇坪县",
        "白河县",
        "旬阳市"
      ],
      商洛市: [
        "商州区",
        "洛南县",
        "丹凤县",
        "商南县",
        "山阳县",
        "镇安县",
        "柞水县"
      ]
    },
    甘肃省: {
      兰州市: [
        "城关区",
        "七里河区",
        "西固区",
        "安宁区",
        "红古区",
        "永登县",
        "皋兰县",
        "榆中县",
        "兰州新区"
      ],
      嘉峪关市: [
        "雄关街道",
        "钢城街道",
        "新城镇",
        "峪泉镇",
        "文殊镇"
      ],
      金昌市: [
        "金川区",
        "永昌县"
      ],
      白银市: [
        "白银区",
        "平川区",
        "靖远县",
        "会宁县",
        "景泰县"
      ],
      天水市: [
        "秦州区",
        "麦积区",
        "清水县",
        "秦安县",
        "甘谷县",
        "武山县",
        "张家川回族自治县"
      ],
      武威市: [
        "凉州区",
        "民勤县",
        "古浪县",
        "天祝藏族自治县"
      ],
      张掖市: [
        "甘州区",
        "肃南裕固族自治县",
        "民乐县",
        "临泽县",
        "高台县",
        "山丹县"
      ],
      平凉市: [
        "崆峒区",
        "泾川县",
        "灵台县",
        "崇信县",
        "庄浪县",
        "静宁县",
        "华亭市"
      ],
      酒泉市: [
        "肃州区",
        "金塔县",
        "瓜州县",
        "肃北蒙古族自治县",
        "阿克塞哈萨克族自治县",
        "玉门市",
        "敦煌市"
      ],
      庆阳市: [
        "西峰区",
        "庆城县",
        "环县",
        "华池县",
        "合水县",
        "正宁县",
        "宁县",
        "镇原县"
      ],
      定西市: [
        "安定区",
        "通渭县",
        "陇西县",
        "渭源县",
        "临洮县",
        "漳县",
        "岷县"
      ],
      陇南市: [
        "武都区",
        "成县",
        "文县",
        "宕昌县",
        "康县",
        "西和县",
        "礼县",
        "徽县",
        "两当县"
      ],
      临夏: [
        "临夏市",
        "临夏县",
        "康乐县",
        "永靖县",
        "广河县",
        "和政县",
        "东乡族自治县",
        "积石山保安族东乡族撒拉族自治县"
      ],
      甘南: [
        "合作市",
        "临潭县",
        "卓尼县",
        "舟曲县",
        "迭部县",
        "玛曲县",
        "碌曲县",
        "夏河县"
      ]
    },
    青海省: {
      西宁市: [
        "城东区",
        "城中区",
        "城西区",
        "城北区",
        "湟中区",
        "大通回族土族自治县",
        "湟源县"
      ],
      海东市: [
        "乐都区",
        "平安区",
        "民和回族土族自治县",
        "互助土族自治县",
        "化隆回族自治县",
        "循化撒拉族自治县"
      ],
      海北: [
        "门源回族自治县",
        "祁连县",
        "海晏县",
        "刚察县"
      ],
      黄南: [
        "同仁市",
        "尖扎县",
        "泽库县",
        "河南蒙古族自治县"
      ],
      海南: [
        "共和县",
        "同德县",
        "贵德县",
        "兴海县",
        "贵南县"
      ],
      果洛: [
        "玛沁县",
        "班玛县",
        "甘德县",
        "达日县",
        "久治县",
        "玛多县"
      ],
      玉树: [
        "玉树市",
        "杂多县",
        "称多县",
        "治多县",
        "囊谦县",
        "曲麻莱县"
      ],
      海西: [
        "格尔木市",
        "德令哈市",
        "茫崖市",
        "乌兰县",
        "都兰县",
        "天峻县",
        "大柴旦行政委员会"
      ]
    },
    宁夏: {
      银川市: [
        "兴庆区",
        "西夏区",
        "金凤区",
        "永宁县",
        "贺兰县",
        "灵武市"
      ],
      石嘴山市: [
        "大武口区",
        "惠农区",
        "平罗县"
      ],
      吴忠市: [
        "利通区",
        "红寺堡区",
        "盐池县",
        "同心县",
        "青铜峡市"
      ],
      固原市: [
        "原州区",
        "西吉县",
        "隆德县",
        "泾源县",
        "彭阳县"
      ],
      中卫市: [
        "沙坡头区",
        "中宁县",
        "海原县"
      ]
    },
    新疆: {
      乌鲁木齐市: [
        "天山区",
        "沙依巴克区",
        "新市区",
        "水磨沟区",
        "头屯河区",
        "达坂城区",
        "米东区",
        "乌鲁木齐县"
      ],
      克拉玛依市: [
        "独山子区",
        "克拉玛依区",
        "白碱滩区",
        "乌尔禾区"
      ],
      吐鲁番市: [
        "高昌区",
        "鄯善县",
        "托克逊县"
      ],
      哈密市: [
        "伊州区",
        "巴里坤哈萨克自治县",
        "伊吾县"
      ],
      昌吉: [
        "昌吉市",
        "阜康市",
        "呼图壁县",
        "玛纳斯县",
        "奇台县",
        "吉木萨尔县",
        "木垒哈萨克自治县"
      ],
      博尔塔拉: [
        "博乐市",
        "阿拉山口市",
        "精河县",
        "温泉县"
      ],
      巴音郭楞: [
        "库尔勒市",
        "轮台县",
        "尉犁县",
        "若羌县",
        "且末县",
        "焉耆回族自治县",
        "和静县",
        "和硕县",
        "博湖县"
      ],
      阿克苏: [
        "阿克苏市",
        "库车市",
        "温宿县",
        "沙雅县",
        "新和县",
        "拜城县",
        "乌什县",
        "阿瓦提县",
        "柯坪县"
      ],
      克孜勒苏: [
        "阿图什市",
        "阿克陶县",
        "阿合奇县",
        "乌恰县"
      ],
      喀什: [
        "喀什市",
        "疏附县",
        "疏勒县",
        "英吉沙县",
        "泽普县",
        "莎车县",
        "叶城县",
        "麦盖提县",
        "岳普湖县",
        "伽师县",
        "巴楚县",
        "塔什库尔干塔吉克自治县"
      ],
      和田: [
        "和田市",
        "和田县",
        "墨玉县",
        "皮山县",
        "洛浦县",
        "策勒县",
        "于田县",
        "民丰县"
      ],
      伊犁: [
        "伊宁市",
        "奎屯市",
        "霍尔果斯市",
        "伊宁县",
        "察布查尔锡伯自治县",
        "霍城县",
        "巩留县",
        "新源县",
        "昭苏县",
        "特克斯县",
        "尼勒克县"
      ],
      塔城: [
        "塔城市",
        "乌苏市",
        "沙湾市",
        "额敏县",
        "托里县",
        "裕民县",
        "和布克赛尔蒙古自治县"
      ],
      阿勒泰: [
        "阿勒泰市",
        "布尔津县",
        "富蕴县",
        "福海县",
        "哈巴河县",
        "青河县",
        "吉木乃县"
      ],
      
      石河子市:[
"石河子市"
      ],
      阿拉尔市:[
"阿拉尔市"
      ],
      图木舒克市:[
"图木舒克市"
      ],
      五家渠市:[
"五家渠市"
      ],
      北屯市:[
"北屯市"
      ],
      铁门关市:[
"铁门关市"
      ],
      双河市:[
"双河市"
      ],
      可克达拉市:[
"可克达拉市"
      ],
      昆玉市:[
"昆玉市"
      ],
      胡杨河市:[
"胡杨河市"
      ],
      新星市:[
"新星市"
      ],
      白杨市:[
"白杨市"
      ],
    },
    香港:{
            
        "香港": [
          "深水埗区",
          "油尖旺区",
          "九龙城区",
          "黄大仙区",
          "观塘区",
          "北区",
          "大埔区",
          "沙田区",
          "西贡区",
          "元朗区",
          "屯门区",
          "荃湾区",
          "葵青区",
          "离岛区",
          "中西区",
          "湾仔区",
          "东区",
          "南区",
          "其他"
        ]
      },
      澳门:{
        
        "澳门": [
          "花地玛堂区",
          "圣安多尼堂区",
          "大堂区",
          "望德堂区",
          "风顺堂区",
          "嘉模堂区",
          "圣方济各堂区",
          "路凼",
          "其他"
        ]
      },
    台湾省:{

            台北市: [
              "内湖区",
              "南港区",
              "中正区",
              "万华区",
              "大同区",
              "中山区",
              "松山区",
              "大安区",
              "信义区",
              "文山区",
              "士林区",
              "北投区"
            ],
          
         
            新北市: [
              "板桥区",
              "汐止区",
              "新店区",
              "其他"
            ],
          
        
            桃园市: [
              "桃园市"
            ],
            台中市: [
                "台中市"
              ],
              台南市: [
                "台南市"
              ],
              高雄市: [
                "高雄市"
              ]
          }, 
        
           
   }
  
  let provincialList= Object.entries(i).map(
    ([c, e]) => ({
      label: c,
      value: c,
      children: Object.entries(e).map(([n, l]) => ({
        label: n,
        value: n,
        children: l.map((o) => ({
          label: o,
          value: o
        }))
      }))
    })
  );
  let provincial = Object.entries(i).map(([c, e]) => ({
    label: c,
    value: c,
    children: Object.entries(e).map(([n, l]) => ({
      label: n,
      value: n
    }))
  }));
//   console.log(provincial);

// function transformProvincesData(provincesData) {
//   const transformedData = [];

//   for (const provinceName in provincesData) {
//       if (provincesData.hasOwnProperty(provinceName)) {
//           const cities = provincesData[provinceName];
//           const cityEntries = Object.entries(cities);

//           transformedData.push({
//               name: provinceName,
//               citys: [
//                   {
//                       name: "不限",
//                       areas: ["不限"]
//                   }
//               ].concat(
//                   cityEntries.map(([cityName, areas]) => ({
//                       name: cityName,
//                       areas: ["不限", ...areas]
//                   }))
//               )
//           });
//       }
//   }

//   return transformedData;
// }
// 转换数据
// const resultData = transformProvincesData(i);

// console.log(resultData);
// var DISTRICTS = {
//   "100000": {
//       "110000": "北京市",
//       "120000": "天津市",
//       "130000": "河北省",
//       "140000": "山西省",
//       "150000": "内蒙古",
//       "210000": "辽宁省",
//       "220000": "吉林省",
//       "230000": "黑龙江省",
//       "310000": "上海市",
//       "320000": "江苏省",
//       "330000": "浙江省",
//       "340000": "安徽省",
//       "350000": "福建省",
//       "360000": "江西省",
//       "370000": "山东省",
//       "410000": "河南省",
//       "420000": "湖北省",
//       "430000": "湖南省",
//       "440000": "广东省",
//       "450000": "广西",
//       "460000": "海南省",
//       "500000": "重庆市",
//       "510000": "四川省",
//       "520000": "贵州省",
//       "530000": "云南省",
//       "540000": "西藏",
//       "610000": "陕西省",
//       "620000": "甘肃省",
//       "630000": "青海省",
//       "640000": "宁夏",
//       "650000": "新疆",
//       "710000": "台湾省",
//       "810000": "香港",
//       "820000": "澳门"
//   },
//   "110000": {
//       "110101": "东城区",
//       "110102": "西城区",
//       "110103": "崇文区",
//       "110104": "宣武区",
//       "110105": "朝阳区",
//       "110106": "丰台区",
//       "110107": "石景山区",
//       "110108": "海淀区",
//       "110109": "门头沟区",
//       "110111": "房山区",
//       "110112": "通州区",
//       "110113": "顺义区",
//       "110114": "昌平区",
//       "110115": "大兴区",
//       "110116": "怀柔区",
//       "110117": "平谷区",
//       "110228": "密云区",
//       "110229": "延庆区"
//   },
//   "120000": {
//       "120101": "和平区",
//       "120102": "河东区",
//       "120103": "河西区",
//       "120104": "南开区",
//       "120105": "河北区",
//       "120106": "红桥区",
//       "120107": "塘沽区",
//       "120108": "汉沽区",
//       "120109": "大港区",
//       "120110": "东丽区",
//       "120111": "西青区",
//       "120112": "津南区",
//       "120113": "北辰区",
//       "120114": "武清区",
//       "120115": "宝坻区",
//       "120221": "宁河区",
//       "120223": "静海区",
//       "120225": "蓟县"
//   },
//   "130000": {
//       "130100": "石家庄市",
//       "130200": "唐山市",
//       "130300": "秦皇岛市",
//       "130400": "邯郸市",
//       "130500": "邢台市",
//       "130600": "保定市",
//       "130700": "张家口市",
//       "130800": "承德市",
//       "130900": "沧州市",
//       "131000": "廊坊市",
//       "131100": "衡水市"
//   },
//   "130100": {
//       "130102": "长安区",
//       "130103": "桥东区",
//       "130104": "桥西区",
//       "130105": "新华区",
//       "130107": "井陉矿区",
//       "130121": "井陉县",
//       "130123": "正定县",
//       "130124": "栾城县",
//       "130125": "行唐县",
//       "130126": "灵寿县",
//       "130127": "高邑县",
//       "130128": "深泽县",
//       "130129": "赞皇县",
//       "130130": "无极县",
//       "130131": "平山县",
//       "130132": "元氏县",
//       "130133": "赵县",
//       "130181": "辛集市",
//       "130182": "藁市",
//       "130183": "晋州市",
//       "130184": "新乐市",
//       "130185": "鹿泉市"
//   },
//   "130200": {
//       "130202": "路南区",
//       "130203": "路北区",
//       "130204": "古冶区",
//       "130205": "开平区",
//       "130207": "丰南区",
//       "130208": "丰润区",
//       "130223": "滦县",
//       "130224": "滦南县",
//       "130225": "乐亭县",
//       "130227": "迁西县",
//       "130229": "玉田县",
//       "130230": "唐海县",
//       "130281": "遵化市",
//       "130283": "迁安市"
//   },
//   "130300": {
//       "130302": "海港区",
//       "130303": "山海关区",
//       "130304": "北戴河区",
//       "130321": "青龙",
//       "130322": "昌黎县",
//       "130323": "抚宁县",
//       "130324": "卢龙县"
//   },
//   "130400": {
//       "130402": "邯山区",
//       "130403": "丛台区",
//       "130404": "复兴区",
//       "130406": "峰峰矿区",
//       "130421": "邯郸县",
//       "130423": "临漳县",
//       "130424": "成安县",
//       "130425": "大名县",
//       "130426": "涉县",
//       "130427": "磁县",
//       "130428": "肥乡县",
//       "130429": "永年县",
//       "130430": "邱县",
//       "130431": "鸡泽县",
//       "130432": "广平县",
//       "130433": "馆陶县",
//       "130434": "魏县",
//       "130435": "曲周县",
//       "130481": "武安市"
//   },
//   "130500": {
//       "130502": "桥东区",
//       "130503": "桥西区",
//       "130521": "邢台县",
//       "130522": "临城县",
//       "130523": "内丘县",
//       "130524": "柏乡县",
//       "130525": "隆尧县",
//       "130526": "任县",
//       "130527": "南和县",
//       "130528": "宁晋县",
//       "130529": "巨鹿县",
//       "130530": "新河县",
//       "130531": "广宗县",
//       "130532": "平乡县",
//       "130533": "威县",
//       "130534": "清河县",
//       "130535": "临西县",
//       "130581": "南宫市",
//       "130582": "沙河市"
//   },
//   "130600": {
//       "130602": "新市区",
//       "130603": "北市区",
//       "130604": "南市区",
//       "130621": "满城县",
//       "130622": "清苑县",
//       "130623": "涞水县",
//       "130624": "阜平县",
//       "130625": "徐水县",
//       "130626": "定兴县",
//       "130627": "唐县",
//       "130628": "高阳县",
//       "130629": "容城县",
//       "130630": "涞源县",
//       "130631": "望都县",
//       "130632": "安新县",
//       "130633": "易县",
//       "130634": "曲阳县",
//       "130635": "蠡县",
//       "130636": "顺平县",
//       "130637": "博野",
//       "130638": "雄县",
//       "130681": "涿州市",
//       "130682": "定州市",
//       "130683": "安国市",
//       "130684": "高碑店市"
//   },
//   "130700": {
//       "130702": "桥东区",
//       "130703": "桥西区",
//       "130705": "宣化区",
//       "130706": "下花园区",
//       "130721": "宣化县",
//       "130722": "张北县",
//       "130723": "康保县",
//       "130724": "沽源县",
//       "130725": "尚义县",
//       "130726": "蔚县",
//       "130727": "阳原县",
//       "130728": "怀安县",
//       "130729": "万全区",
//       "130730": "怀来县",
//       "130731": "涿鹿县",
//       "130732": "赤城县",
//       "130733": "崇礼县"
//   },
//   "130800": {
//       "130802": "双桥区",
//       "130803": "双滦区",
//       "130804": "鹰手营子矿区",
//       "130821": "承德县",
//       "130822": "兴隆县",
//       "130823": "平泉市",
//       "130824": "滦平县",
//       "130825": "隆化县",
//       "130826": "丰宁",
//       "130827": "宽城",
//       "130828": "围场"
//   },
//   "130900": {
//       "130902": "新华区",
//       "130903": "运河区",
//       "130921": "沧县",
//       "130922": "青县",
//       "130923": "东光县",
//       "130924": "海兴县",
//       "130925": "盐山县",
//       "130926": "肃宁县",
//       "130927": "南皮县",
//       "130928": "吴桥县",
//       "130929": "献县",
//       "130930": "孟村",
//       "130981": "泊头市",
//       "130982": "任丘市",
//       "130983": "黄骅市",
//       "130984": "河间市"
//   },
//   "131000": {
//       "131002": "安次区",
//       "131022": "固安县",
//       "131023": "永清县",
//       "131024": "香河县",
//       "131025": "大城县",
//       "131026": "文安县",
//       "131028": "大厂",
//       "131081": "霸州市",
//       "131082": "三河市"
//   },
//   "131100": {
//       "131102": "桃城区",
//       "131121": "枣强县",
//       "131122": "武邑县",
//       "131123": "武强县",
//       "131124": "饶阳县",
//       "131125": "安平县",
//       "131126": "故城县",
//       "131127": "景县",
//       "131128": "阜城县",
//       "131181": "冀州市",
//       "131182": "深州市"
//   },
//   "140000": {
//       "140100": "太原",
//       "140200": "大同",
//       "140300": "阳泉",
//       "140400": "长治",
//       "140500": "晋城",
//       "140600": "朔州",
//       "140700": "晋中",
//       "140800": "运城",
//       "140900": "忻州",
//       "141000": "临汾",
//       "141100": "吕梁"
//   },
//   "140100": {
//       "140105": "小店区",
//       "140106": "迎泽区",
//       "140107": "杏花岭区",
//       "140108": "尖草坪区",
//       "140109": "万柏林区",
//       "140110": "晋源区",
//       "140121": "清徐县",
//       "140122": "阳曲县",
//       "140123": "娄烦县",
//       "140181": "古交市"
//   },
//   "140200": {
//       "140202": "城区",
//       "140212": "新荣区",
//       "140221": "阳高县",
//       "140222": "天镇县",
//       "140223": "广灵县",
//       "140224": "灵丘县",
//       "140225": "浑源县",
//       "140226": "左云县"
//   },
//   "140300": {
//       "140302": "城区",
//       "140303": "矿区",
//       "140311": "郊区",
//       "140321": "平定县",
//       "140322": "盂县"
//   },
//   "140400": {
//       "140402": "城区",
//       "140411": "郊区",
//       "140421": "长治县",
//       "140423": "襄垣县",
//       "140424": "屯留县",
//       "140425": "平顺县",
//       "140426": "黎城县",
//       "140427": "壶关县",
//       "140428": "长子县",
//       "140429": "武乡县",
//       "140430": "沁县",
//       "140431": "沁源县",
//       "140481": "潞城市"
//   },
//   "140500": {
//       "140502": "城区",
//       "140521": "沁水县",
//       "140522": "阳城县",
//       "140524": "陵川县",
//       "140525": "泽州县",
//       "140581": "高平市"
//   },
//   "140600": {
//       "140602": "朔城区",
//       "140603": "平鲁区",
//       "140621": "山阴县",
//       "140622": "应县",
//       "140623": "右玉县",
//       "140624": "怀仁县"
//   },
//   "140700": {
//       "140702": "榆次市",
//       "140721": "榆社县",
//       "140722": "左权县",
//       "140723": "和顺县",
//       "140724": "昔阳县",
//       "140725": "寿阳县",
//       "140726": "太谷县",
//       "140727": "祁县",
//       "140728": "平遥县",
//       "140729": "灵石县",
//       "140781": "介休市"
//   },
//   "140800": {
//       "140821": "临猗县",
//       "140822": "万荣县",
//       "140823": "闻喜县",
//       "140824": "稷山县",
//       "140825": "新绛县",
//       "140826": "绛县",
//       "140827": "垣曲县",
//       "140828": "夏县",
//       "140829": "平陆县",
//       "140830": "芮城县",
//       "140881": "永济市",
//       "140882": "河津市"
//   },
//   "140900": {
//       "140902": "忻府区",
//       "140921": "定襄县",
//       "140922": "五台县",
//       "140923": "代县",
//       "140924": "繁峙县",
//       "140925": "宁武县",
//       "140926": "静乐县",
//       "140927": "神池县",
//       "140928": "五寨县",
//       "140929": "岢岚县",
//       "140930": "河曲县",
//       "140931": "保德县",
//       "140932": "偏关县",
//       "140981": "原平市"
//   },
//   "141000": {
//       "141021": "曲沃县",
//       "141022": "翼城县",
//       "141023": "襄汾县",
//       "141024": "洪洞县",
//       "141025": "古县",
//       "141026": "安泽县",
//       "141027": "浮山县",
//       "141028": "吉县",
//       "141029": "乡宁县",
//       "141030": "大宁县",
//       "141031": "隰县",
//       "141032": "永和县",
//       "141033": "蒲县",
//       "141034": "汾西县",
//       "141081": "侯马市",
//       "141082": "霍州市"
//   },
//   "141100": {
//       "141102": "离石区",
//       "141121": "文水县",
//       "141122": "交城县",
//       "141123": "兴县",
//       "141124": "临县",
//       "141125": "柳林县",
//       "141126": "石楼县",
//       "141127": "岚县",
//       "141128": "方山县",
//       "141129": "中阳县",
//       "141130": "交口县",
//       "141181": "孝义市",
//       "141182": "汾阳市"
//   },
//   "150000": {
//       "150100": "呼和浩特市",
//       "150200": "包头市",
//       "150300": "乌海市",
//       "150400": "赤峰市",
//       "150500": "通辽市",
//       "150700": "呼伦贝尔市",
//       "150800": "巴彦淖尔市",
//       "150900": "乌兰察布市",
//       "153900": "鄂尔多斯市",
//       "152200": "兴安盟",
//       "152500": "锡林郭勒盟",
//       "152900": "阿拉善盟"
      
//   },
//   "150100": {
//       "150102": "新城区",
//       "150103": "回民区",
//       "150104": "玉泉区",
//       "150121": "土默特左旗",
//       "150122": "托克托县",
//       "150123": "和林格尔县",
//       "150124": "清水河县",
//       "150125": "武川县"
//   },
//   "150200": {
//       "150202": "东河区",
//       "150203": "昆都仑区",
//       "150204": "青山区",
//       "150205": "石拐矿区",
//       "150206": "白云矿区",
//       "150221": "土默特右旗",
//       "150222": "固阳县",
//       "150223": "达尔罕茂明安联合旗"
//   },
//   "150300": {
//       "150302": "海勃湾区",
//       "150303": "海南区",
//       "150304": "乌达区"
//   },
//   "150400": {
//       "150402": "红山区",
//       "150403": "元宝山区",
//       "150404": "松山区",
//       "150421": "阿鲁科尔沁旗",
//       "150422": "巴林左旗",
//       "150423": "巴林右旗",
//       "150424": "林西县",
//       "150425": "克什克腾旗",
//       "150426": "翁牛特旗",
//       "150428": "喀喇沁旗",
//       "150429": "宁城县",
//       "150430": "敖汉旗"
//   },
//   "150500": {
//       "150502": "科尔沁区",
//       "150521": "科尔沁左翼中旗",
//       "150522": "科尔沁左翼后旗",
//       "150523": "开鲁县",
//       "150524": "库伦旗",
//       "150525": "奈曼旗",
//       "150526": "扎鲁特旗",
//       "150581": "霍林郭勒市"
//   },
//   "150700": {
//       "150702": "海拉尔区",
//       "150721": "阿荣旗",
//       "150722": "莫力达瓦达斡尔",
//       "150723": "鄂伦春",
//       "150724": "鄂温克",
//       "150725": "陈巴尔虎旗",
//       "150726": "新巴尔虎左旗",
//       "150727": "新巴尔虎右旗",
//       "150781": "满洲里市",
//       "150782": "牙克石市",
//       "150783": "扎兰屯市",
//       "150784": "额尔古纳市",
//       "150785": "根河市"
//   },
//   "150800": {
//       "150802": "临河市",
//       "150821": "五原县",
//       "150822": "磴口县",
//       "150823": "乌拉特前旗",
//       "150824": "乌拉特中旗",
//       "150825": "乌拉特后旗",
//       "150826": "杭锦后旗"
//   },
//   "150900": {
//       "150902": "集宁区",
//       "150921": "卓资县",
//       "150922": "化德县",
//       "150923": "商都县",
//       "150924": "兴和县",
//       "150925": "凉城县",
//       "150926": "察哈尔右翼前旗",
//       "150927": "察哈尔右翼中旗",
//       "150928": "察哈尔右翼后旗",
//       "150929": "四子王旗",
//       "150981": "丰镇市"
//   },
//   "152200": {
//       "152201": "乌兰浩特市",
//       "152202": "阿尔山市",
//       "152221": "科尔沁右翼前旗",
//       "152222": "科尔沁右翼中旗",
//       "152223": "扎赉特旗",
//       "152224": "突泉县"
//   },
//   "153900": {
//     "153201": "东胜区",
//     "153202":"康巴什区",
//     "153221": "达拉特旗",
//     "153222": "准格尔旗",
//     "153223":"鄂托克前旗",
//     "153224":"鄂托克旗",
//     "153229":"杭锦旗",
//     "153228" :"乌审旗",
//     "153227":"伊金霍洛旗"  
//   },
//   "152500": {
//       "152501": "二连浩特市",
//       "152502": "锡林浩特市",
//       "152522": "阿巴嘎旗",
//       "152523": "苏尼特左旗",
//       "152524": "苏尼特右旗",
//       "152525": "东乌珠穆沁旗",
//       "152526": "西乌珠穆沁旗",
//       "152527": "太仆寺旗",
//       "152528": "镶黄旗",
//       "152529": "正镶白旗",
//       "152530": "正蓝旗",
//       "152531": "多伦县"
//   },
//   "152900": {
//       "152921": "阿拉善左旗",
//       "152922": "阿拉善右旗",
//       "152923": "额济纳旗"
//   },
//   "210000": {
//       "210100": "沈阳市",
//       "210200": "大连市",
//       "210300": "鞍山市",
//       "210400": "抚顺市",
//       "210500": "本溪市",
//       "210600": "丹东市",
//       "210700": "锦州市",
//       "210800": "营口市",
//       "210900": "阜新市",
//       "211000": "辽阳市",
//       "211100": "盘锦市",
//       "211200": "铁岭市",
//       "211300": "朝阳市",
//       "211400": "葫芦岛市"
//   },
//   "210100": {
//       "210102": "和平区",
//       "210103": "沈河区",
//       "210104": "大东区",
//       "210105": "皇姑区",
//       "210106": "铁西区",
//       "210111": "苏家屯区",
//       "210114": "于洪区",
//       "210122": "辽中县",
//       "210123": "康平县",
//       "210124": "法库县",
//       "210181": "新民市"
//   },
//   "210200": {
//       "210202": "中山区",
//       "210203": "西岗区",
//       "210204": "沙河口区",
//       "210211": "甘井子区",
//       "210212": "旅顺口区",
//       "210213": "金州区",
//       "210224": "长海县",
//       "210281": "瓦房店市",
//       "210282": "普兰店市",
//       "210283": "庄河市"
//   },
//   "210300": {
//       "210302": "铁东区",
//       "210303": "铁西区",
//       "210304": "立山区",
//       "210311": "千山区",
//       "210321": "台安县",
//       "210323": "岫岩",
//       "210381": "海城市"
//   },
//   "210400": {
//       "210402": "新抚区",
//       "210403": "东洲区",
//       "210404": "望花区",
//       "210411": "顺城区",
//       "210421": "抚顺县",
//       "210422": "新宾",
//       "210423": "清原"
//   },
//   "210500": {
//       "210502": "平山区",
//       "210503": "溪湖区",
//       "210504": "明山区",
//       "210505": "南芬区",
//       "210521": "本溪",
//       "210522": "桓仁"
//   },
//   "210600": {
//       "210602": "元宝区",
//       "210603": "振兴区",
//       "210604": "振安区",
//       "210624": "宽甸",
//       "210681": "东港市",
//       "210682": "凤城市"
//   },
//   "210700": {
//       "210702": "古塔区",
//       "210703": "凌河区",
//       "210711": "太和区",
//       "210726": "黑山县",
//       "210727": "义县",
//       "210781": "凌海市",
//       "210782": "北宁市"
//   },
//   "210800": {
//       "210802": "站前区",
//       "210803": "西市区",
//       "210804": "鲅鱼圈区",
//       "210811": "老边区",
//       "210881": "盖州市",
//       "210882": "大石桥市"
//   },
//   "210900": {
//       "210902": "海州区",
//       "210903": "新邱区",
//       "210904": "太平区",
//       "210905": "清河门区",
//       "210911": "细河区",
//       "210921": "阜新",
//       "210922": "彰武县"
//   },
//   "211000": {
//       "211002": "白塔区",
//       "211003": "文圣区",
//       "211004": "宏伟区",
//       "211005": "弓长岭区",
//       "211011": "太子河区",
//       "211021": "辽阳县",
//       "211081": "灯塔市"
//   },
//   "211100": {
//       "211102": "双台子区",
//       "211103": "兴隆台区",
//       "211121": "大洼区",
//       "211122": "盘山县"
//   },
//   "211200": {
//       "211202": "银州区",
//       "211204": "清河区",
//       "211221": "铁岭县",
//       "211223": "西丰县",
//       "211224": "昌图县",
//       "211281": "调兵山市",
//       "211282": "开原市"
//   },
//   "211300": {
//       "211302": "双塔区",
//       "211303": "龙城区",
//       "211321": "朝阳县",
//       "211322": "建平县",
//       "211324": "喀喇沁左翼",
//       "211381": "北票市",
//       "211382": "凌源市"
//   },
//   "211400": {
//       "211402": "连山区",
//       "211403": "龙港区",
//       "211404": "南票区",
//       "211421": "绥中县",
//       "211422": "建昌县",
//       "211481": "兴城市"
//   },
//   "220000": {
//       "220100": "长春",
//       "220200": "吉林省",
//       "220300": "四平",
//       "220400": "辽源",
//       "220500": "通化",
//       "220600": "白山",
//       "220700": "松原",
//       "220800": "白城",
//       "222400": "延边"
//   },
//   "220100": {
//       "220102": "南关区",
//       "220103": "宽城区",
//       "220104": "朝阳区",
//       "220105": "二道区",
//       "220106": "绿园区",
//       "220112": "双阳区",
//       "220122": "农安县",
//       "220181": "九台区",
//       "220182": "榆树市",
//       "220183": "德惠市"
//   },
//   "220200": {
//       "220202": "昌邑区",
//       "220203": "龙潭区",
//       "220204": "船营区",
//       "220211": "丰满区",
//       "220221": "永吉县",
//       "220281": "蛟河市",
//       "220282": "桦甸市",
//       "220283": "舒兰市",
//       "220284": "磐石市"
//   },
//   "220300": {
//       "220302": "铁西区",
//       "220303": "铁东区",
//       "220322": "梨树县",
//       "220323": "伊通",
//       "220381": "公主岭市",
//       "220382": "双辽市"
//   },
//   "220400": {
//       "220402": "龙山区",
//       "220403": "西安区",
//       "220421": "东丰县",
//       "220422": "东辽县"
//   },
//   "220500": {
//       "220502": "东昌区",
//       "220503": "二道江区",
//       "220521": "通化县",
//       "220523": "辉南县",
//       "220524": "柳河县",
//       "220581": "梅河口市",
//       "220582": "集安市"
//   },
//   "220600": {
//       "220602": "八道江区",
//       "220621": "抚松县",
//       "220622": "靖宇县",
//       "220623": "长白",
//       "220625": "江源区",
//       "220681": "临江市"
//   },
//   "220700": {
//       "220702": "宁江区",
//       "220721": "前郭尔罗斯",
//       "220722": "长岭县",
//       "220723": "乾安县",
//       "220724": "扶余市"
//   },
//   "220800": {
//       "220802": "洮北区",
//       "220821": "镇赉县",
//       "220822": "通榆县",
//       "220881": "洮南市",
//       "220882": "大安市"
//   },
//   "222400": {
//       "222401": "延吉市",
//       "222402": "图们市",
//       "222403": "敦化市",
//       "222404": "珲春市",
//       "222405": "龙井市",
//       "222406": "和龙市",
//       "222424": "汪清县",
//       "222426": "安图县"
//   },
//   "230000": {
//       "230100": "哈尔滨市",
//       "230200": "齐齐哈尔市",
//       "230300": "鸡西市",
//       "230400": "鹤岗市",
//       "230500": "双鸭山市",
//       "230600": "大庆市",
//       "230700": "伊春市",
//       "230800": "佳木斯市",
//       "230900": "七台河市",
//       "231000": "牡丹江市",
//       "231100": "黑河市",
//       "231200": "绥化市",
//       "232700": "大兴安岭"
//   },
//   "230100": {
//       "230102": "道里区",
//       "230103": "南岗区",
//       "230104": "道外区",
//       "230106": "香坊区",
//       "230108": "平房区",
//       "230109": "松北区",
//       "230111": "呼兰区",
//       "230123": "依兰县",
//       "230124": "方正县",
//       "230125": "宾县",
//       "230126": "巴彦县",
//       "230127": "木兰县",
//       "230128": "通河县",
//       "230129": "延寿县",
//       "230181": "阿城区",
//       "230182": "双城市",
//       "230183": "尚志市",
//       "230184": "五常市"
//   },
//   "230200": {
//       "230202": "龙沙区",
//       "230203": "建华区",
//       "230204": "铁锋区",
//       "230205": "昂昂溪区",
//       "230206": "富拉尔基区",
//       "230207": "碾子山区",
//       "230208": "梅里斯达斡尔",
//       "230221": "龙江县",
//       "230223": "依安县",
//       "230224": "泰来县",
//       "230225": "甘南县",
//       "230227": "富裕县",
//       "230229": "克山县",
//       "230230": "克东县",
//       "230231": "拜泉县",
//       "230281": "讷河市"
//   },
//   "230300": {
//       "230302": "鸡冠区",
//       "230303": "恒山区",
//       "230304": "滴道区",
//       "230305": "梨树区",
//       "230306": "城子河区",
//       "230307": "麻山区",
//       "230321": "鸡东县",
//       "230381": "虎林市",
//       "230382": "密山市"
//   },
//   "230400": {
//       "230402": "向阳区",
//       "230403": "工农区",
//       "230404": "南山区",
//       "230405": "兴安区",
//       "230406": "东山区",
//       "230407": "兴山区",
//       "230421": "萝北县",
//       "230422": "绥滨县"
//   },
//   "230500": {
//       "230502": "尖山区",
//       "230503": "岭东区",
//       "230505": "四方台区",
//       "230506": "宝山区",
//       "230521": "集贤县",
//       "230522": "友谊县",
//       "230523": "宝清县",
//       "230524": "饶河县"
//   },
//   "230600": {
//       "230602": "萨尔图区",
//       "230603": "龙凤区",
//       "230604": "让胡路区",
//       "230605": "红岗区",
//       "230606": "大同区",
//       "230621": "肇州县",
//       "230622": "肇源县",
//       "230623": "林甸县",
//       "230624": "杜尔伯特"
//   },
//   "230700": {
//       "230702": "伊春区",
//       "230703": "南岔区",
//       "230704": "友好区",
//       "230705": "西林区",
//       "230706": "翠峦区",
//       "230707": "新青区",
//       "230708": "美溪区",
//       "230709": "金山屯区",
//       "230710": "五营区",
//       "230711": "乌马河区",
//       "230712": "汤旺河区",
//       "230713": "带岭区",
//       "230714": "乌伊岭区",
//       "230715": "红星区",
//       "230716": "上甘岭区",
//       "230722": "嘉荫县",
//       "230781": "铁力市"
//   },
//   "230800": {
//       "230802": "永红区",
//       "230803": "向阳区",
//       "230804": "前进区",
//       "230805": "东风区",
//       "230811": "郊区",
//       "230822": "桦南县",
//       "230826": "桦川县",
//       "230828": "汤原县",
//       "230833": "抚远市",
//       "230881": "同江市",
//       "230882": "富锦市"
//   },
//   "230900": {
//       "230902": "新兴区",
//       "230903": "桃山区",
//       "230904": "茄子河区",
//       "230921": "勃利县"
//   },
//   "231000": {
//       "231002": "东安区",
//       "231003": "阳明区",
//       "231004": "爱民区",
//       "231005": "西安区",
//       "231024": "东宁县",
//       "231025": "林口县",
//       "231081": "绥芬河市",
//       "231083": "海林市",
//       "231084": "宁安市",
//       "231085": "穆棱市"
//   },
//   "231100": {
//       "231102": "爱辉区",
//       "231121": "嫩江县",
//       "231123": "逊克县",
//       "231124": "孙吴县",
//       "231181": "北安市",
//       "231182": "五大连池市"
//   },
//   "231200": {
//       "231202": "北林区",
//       "231221": "望奎县",
//       "231222": "兰西县",
//       "231223": "青冈县",
//       "231224": "庆安县",
//       "231225": "明水县",
//       "231226": "绥棱县",
//       "231281": "安达市",
//       "231282": "肇东市",
//       "231283": "海伦市"
//   },
//   "232700": {
//       "232721": "呼玛县",
//       "232722": "塔河县",
//       "232723": "漠河县"
//   },
//   "310000": {
//       "310101": "黄浦区",
//       "310103": "卢湾区",
//       "310104": "徐汇区",
//       "310105": "长宁区",
//       "310106": "静安区",
//       "310107": "普陀区",
//       "310108": "闸北区",
//       "310109": "虹口区",
//       "310110": "杨浦区",
//       "310112": "闵行区",
//       "310113": "宝山区",
//       "310114": "嘉定区",
//       "310115": "浦东新区",
//       "310116": "金山区",
//       "310117": "松江区",
//       "310118": "青浦区",
//       "310119": "南汇区",
//       "310120": "奉贤区",
//       "310230": "崇明县"
//   },
//   "320000": {
//       "320100": "南京市",
//       "320200": "无锡市",
//       "320300": "徐州市",
//       "320400": "常州市",
//       "320500": "苏州市",
//       "320600": "南通市",
//       "320700": "连云港市",
//       "320800": "淮安市",
//       "320900": "盐城市",
//       "321000": "扬州市",
//       "321100": "镇江市",
//       "321200": "泰州市",
//       "321300": "宿迁市"
//   },
//   "320100": {
//       "320102": "玄武区",
//       "320103": "白下区",
//       "320104": "秦淮区",
//       "320105": "建邺区",
//       "320106": "鼓楼区",
//       "320107": "下关区",
//       "320111": "浦口区",
//       "320113": "栖霞区",
//       "320114": "雨花台区",
//       "320115": "江宁区",
//       "320116": "六合区",
//       "320124": "溧水县",
//       "320125": "高淳县"
//   },
//   "320200": {
//       "320205": "锡山区",
//       "320206": "惠山区",
//       "320211": "滨湖区",
//       "320281": "江阴市",
//       "320282": "宜兴市"
//   },
//   "320300": {
//       "320302": "鼓楼区",
//       "320303": "云龙区",
//       "320304": "九里区",
//       "320305": "贾汪区",
//       "320311": "泉山区",
//       "320321": "丰县",
//       "320322": "沛县",
//       "320323": "铜山区",
//       "320324": "睢宁县",
//       "320381": "新沂市",
//       "320382": "邳州市"
//   },
//   "320400": {
//       "320402": "天宁区",
//       "320404": "钟楼区",
//       "320405": "戚墅堰区",
//       "320411": "新北区",
//       "320412": "武进区",
//       "320481": "溧阳市",
//       "320482": "金坛市"
//   },
//   "320500": {
//       "320502": "沧浪区",
//       "320503": "平江区",
//       "320504": "金阊区",
//       "320505": "虎丘区",
//       "320506": "吴中区",
//       "320507": "相城区",
//       "320581": "常熟市",
//       "320582": "张家港市",
//       "320583": "昆山市",
//       "320584": "吴江区",
//       "320585": "太仓市"
//   },
//   "320600": {
//       "320602": "崇川区",
//       "320611": "港闸区",
//       "320621": "海安县",
//       "320623": "如东县",
//       "320681": "启东市",
//       "320682": "如皋市",
//       "320683": "通州市",
//       "320684": "海门市"
//   },
//   "320700": {
//       "320703": "连云区",
//       "320705": "新浦区",
//       "320706": "海州区",
//       "320721": "赣榆县",
//       "320722": "东海县",
//       "320723": "灌云县",
//       "320724": "灌南县"
//   },
//   "320800": {
//       "320802": "清河区",
//       "320803": "楚州区",
//       "320804": "淮阴区",
//       "320811": "清浦区",
//       "320826": "涟水县",
//       "320829": "洪泽县",
//       "320830": "盱眙县",
//       "320831": "金湖县"
//   },
//   "320900": {
//       "320902": "亭湖区",
//       "320903": "盐都区",
//       "320921": "响水县",
//       "320922": "滨海县",
//       "320923": "阜宁县",
//       "320924": "射阳县",
//       "320925": "建湖县",
//       "320981": "东台市",
//       "320982": "大丰市"
//   },
//   "321000": {
//       "321002": "广陵区",
//       "321003": "邗江区",
//       "321023": "宝应县",
//       "321081": "仪征市",
//       "321084": "高邮市",
//       "321088": "江都区"
//   },
//   "321100": {
//       "321102": "京口区",
//       "321111": "润州区",
//       "321112": "丹徒区",
//       "321181": "丹阳市",
//       "321182": "扬中市",
//       "321183": "句容市"
//   },
//   "321200": {
//       "321202": "海陵区",
//       "321203": "高港区",
//       "321281": "兴化市",
//       "321282": "靖江市",
//       "321283": "泰兴市",
//       "321284": "姜堰区"
//   },
//   "321300": {
//       "321302": "宿城区",
//       "321311": "宿豫区",
//       "321322": "沭阳县",
//       "321323": "泗阳县",
//       "321324": "泗洪县"
//   },
//   "330000": {
//       "330100": "杭州市",
//       "330200": "宁波市",
//       "330300": "温州市",
//       "330400": "嘉兴市",
//       "330500": "湖州市",
//       "330600": "绍兴市",
//       "330700": "金华市",
//       "330800": "衢州市",
//       "330900": "舟山市",
//       "331000": "台州市",
//       "331100": "丽水市"
//   },
//   "330100": {
//       "330102": "上城区",
//       "330103": "下城区",
//       "330104": "江干区",
//       "330105": "拱墅区",
//       "330106": "西湖区",
//       "330108": "滨江区",
//       "330109": "萧山区",
//       "330110": "余杭区",
//       "330122": "桐庐县",
//       "330127": "淳安县",
//       "330182": "建德市",
//       "330183": "富阳市",
//       "330185": "临安市"
//   },
//   "330200": {
//       "330203": "海曙区",
//       "330204": "江东区",
//       "330205": "江北区",
//       "330206": "北仑区",
//       "330211": "镇海区",
//       "330212": "鄞州区",
//       "330225": "象山县",
//       "330226": "宁海县",
//       "330281": "余姚市",
//       "330282": "慈溪市",
//       "330283": "奉化市"
//   },
//   "330300": {
//       "330302": "鹿城区",
//       "330303": "龙湾区",
//       "330304": "瓯海区",
//       "330322": "洞头县",
//       "330324": "永嘉县",
//       "330326": "平阳县",
//       "330327": "苍南县",
//       "330328": "文成县",
//       "330329": "泰顺县",
//       "330381": "瑞安市",
//       "330382": "乐清市"
//   },
//   "330400": {
//       "330411": "秀洲区",
//       "330421": "嘉善县",
//       "330424": "海盐县",
//       "330481": "海宁市",
//       "330482": "平湖市",
//       "330483": "桐乡市"
//   },
//   "330500": {
//       "330502": "吴兴区",
//       "330503": "南浔区",
//       "330521": "德清县",
//       "330522": "长兴县",
//       "330523": "安吉县"
//   },
//   "330600": {
//       "330602": "越城区",
//       "330624": "新昌县",
//       "330681": "诸暨市",
//       "330682": "上虞市",
//       "330683": "嵊州市"
//   },
//   "330700": {
//       "330702": "婺城区",
//       "330703": "金东区",
//       "330723": "武义县",
//       "330726": "浦江县",
//       "330727": "磐安县",
//       "330781": "兰溪市",
//       "330782": "义乌市",
//       "330783": "东阳市",
//       "330784": "永康市"
//   },
//   "330800": {
//       "330802": "柯城区",
//       "330803": "衢江区",
//       "330822": "常山县",
//       "330824": "开化县",
//       "330825": "龙游县",
//       "330881": "江山市"
//   },
//   "330900": {
//       "330902": "定海区",
//       "330903": "普陀区",
//       "330921": "岱山县",
//       "330922": "嵊泗县"
//   },
//   "331000": {
//       "331002": "椒江区",
//       "331003": "黄岩区",
//       "331004": "路桥区",
//       "331021": "玉环县",
//       "331022": "三门县",
//       "331023": "天台县",
//       "331024": "仙居县",
//       "331081": "温岭市",
//       "331082": "临海市"
//   },
//   "331100": {
//       "331102": "莲都区",
//       "331121": "青田县",
//       "331122": "缙云县",
//       "331123": "遂昌县",
//       "331124": "松阳县",
//       "331125": "云和县",
//       "331126": "庆元县",
//       "331127": "景宁",
//       "331181": "龙泉市"
//   },
//   "340000": {
//       "340100": "合肥",
//       "340200": "芜湖",
//       "340300": "蚌埠",
//       "340400": "淮南",
//       "340500": "马鞍山",
//       "340600": "淮北",
//       "340700": "铜陵",
//       "340800": "安庆",
//       "341000": "黄山",
//       "341100": "滁州",
//       "341200": "阜阳",
//       "341300": "宿州",
//       "341400": "巢湖",
//       "341500": "六安",
//       "341600": "亳州",
//       "341700": "池州",
//       "341800": "宣城"
//   },
//   "340100": {
//       "340102": "瑶海区",
//       "340103": "庐阳区",
//       "340104": "蜀山区",
//       "340111": "包河区",
//       "340121": "长丰县",
//       "340122": "肥东县",
//       "340123": "肥西县"
//   },
//   "340200": {
//       "340202": "镜湖区",
//       "340203": "弋江区",
//       "340204": "三山区",
//       "340207": "鸠江区",
//       "340221": "芜湖县",
//       "340222": "繁昌县",
//       "340223": "南陵县"
//   },
//   "340300": {
//       "340302": "龙子湖区",
//       "340303": "蚌山区",
//       "340304": "禹会区",
//       "340311": "淮上区",
//       "340321": "怀远县",
//       "340322": "五河县",
//       "340323": "固镇县"
//   },
//   "340400": {
//       "340402": "大通区",
//       "340403": "田家庵区",
//       "340404": "谢家集区",
//       "340405": "八公山区",
//       "340406": "潘集区",
//       "340421": "凤台县"
//   },
//   "340500": {
//       "340503": "花山区",
//       "340504": "雨山区",
//       "340521": "当涂县"
//   },
//   "340600": {
//       "340602": "杜集区",
//       "340603": "相山区",
//       "340604": "烈山区",
//       "340621": "濉溪县"
//   },
//   "340700": {
//       "340702": "铜官山区",
//       "340711": "郊区"
//   },
//   "340800": {
//       "340802": "迎江区",
//       "340803": "大观区",
//       "340811": "宜秀区",
//       "340822": "怀宁县",
//       "340824": "潜山市",
//       "340825": "太湖县",
//       "340826": "宿松县",
//       "340827": "望江县",
//       "340828": "岳西县",
//       "340881": "桐城市"
//   },
//   "341000": {
//       "341002": "屯溪区",
//       "341003": "黄山区",
//       "341004": "徽州区",
//       "341021": "歙县",
//       "341022": "休宁县",
//       "341023": "黟县",
//       "341024": "祁门县"
//   },
//   "341100": {
//       "341102": "琅琊区",
//       "341103": "南谯区",
//       "341122": "来安县",
//       "341124": "全椒县",
//       "341125": "定远县",
//       "341126": "凤阳县",
//       "341181": "天长市",
//       "341182": "明光市"
//   },
//   "341200": {
//       "341202": "颍州区",
//       "341203": "颍东区",
//       "341204": "颍泉区",
//       "341221": "临泉县",
//       "341222": "太和县",
//       "341225": "阜南县",
//       "341226": "颍上县",
//       "341282": "界首市"
//   },
//   "341300": {
//       "341302": "墉桥区",
//       "341321": "砀山县",
//       "341322": "萧县",
//       "341323": "灵璧县",
//       "341324": "泗县"
//   },
//   "341400": {
//       "341402": "居巢区",
//       "341421": "庐江县",
//       "341422": "无为县"
//   },
//   "341500": {
//       "341502": "金安区",
//       "341503": "裕安区",
//       "341521": "寿县",
//       "341522": "霍邱县",
//       "341523": "舒城县",
//       "341524": "金寨县",
//       "341525": "霍山县"
//   },
//   "341600": {
//       "341602": "谯城区",
//       "341621": "涡阳县",
//       "341622": "蒙城县",
//       "341623": "利辛县"
//   },
//   "341700": {
//       "341702": "贵池区",
//       "341721": "东至县",
//       "341722": "石台县",
//       "341723": "青阳县"
//   },
//   "341800": {
//       "341802": "宣州区",
//       "341821": "郎溪县",
//       "341822": "广德县",
//       "341823": "泾县",
//       "341824": "绩溪县",
//       "341825": "旌德县",
//       "341881": "宁国市"
//   },
//   "350000": {
//       "350100": "福州市",
//       "350200": "厦门市",
//       "350300": "莆田市",
//       "350400": "三明市",
//       "350500": "泉州市",
//       "350600": "漳州市",
//       "350700": "南平市",
//       "350800": "龙岩市",
//       "350900": "宁德市"
//   },
//   "350100": {
//       "350102": "鼓楼区",
//       "350103": "台江区",
//       "350104": "仓山区",
//       "350105": "马尾区",
//       "350111": "晋安区",
//       "350121": "闽侯县",
//       "350122": "连江县",
//       "350123": "罗源县",
//       "350124": "闽清县",
//       "350125": "永泰县",
//       "350128": "平潭县",
//       "350181": "福清市",
//       "350182": "长乐市"
//   },
//   "350200": {
//       "350203": "思明区",
//       "350205": "海沧区",
//       "350206": "湖里区",
//       "350211": "集美区",
//       "350212": "同安区",
//       "350213": "翔安区"
//   },
//   "350300": {
//       "350302": "城厢区",
//       "350303": "涵江区",
//       "350304": "荔城区",
//       "350305": "秀屿区",
//       "350322": "仙游县"
//   },
//   "350400": {
//       "350402": "梅列区",
//       "350403": "三元区",
//       "350421": "明溪县",
//       "350423": "清流县",
//       "350424": "宁化县",
//       "350425": "大田县",
//       "350426": "尤溪县",
//       "350427": "沙县",
//       "350428": "将乐县",
//       "350429": "泰宁县",
//       "350430": "建宁县",
//       "350481": "永安市"
//   },
//   "350500": {
//       "350502": "鲤城区",
//       "350503": "丰泽区",
//       "350504": "洛江区",
//       "350505": "泉港区",
//       "350521": "惠安县",
//       "350524": "安溪县",
//       "350525": "永春县",
//       "350526": "德化县",
//       "350527": "金门县",
//       "350581": "石狮市",
//       "350582": "晋江市",
//       "350583": "南安市"
//   },
//   "350600": {
//       "350602": "芗城区",
//       "350603": "龙文区",
//       "350622": "云霄县",
//       "350623": "漳浦县",
//       "350624": "诏安县",
//       "350625": "长泰县",
//       "350626": "东山县",
//       "350627": "南靖县",
//       "350628": "平和县",
//       "350629": "华安县",
//       "350681": "龙海市"
//   },
//   "350700": {
//       "350702": "延平区",
//       "350721": "顺昌县",
//       "350722": "浦城县",
//       "350723": "光泽县",
//       "350724": "松溪县",
//       "350725": "政和县",
//       "350781": "邵武市",
//       "350782": "武夷山市",
//       "350783": "建瓯市",
//       "350784": "建阳市"
//   },
//   "350800": {
//       "350802": "新罗区",
//       "350821": "长汀县",
//       "350822": "永定区",
//       "350823": "上杭县",
//       "350824": "武平县",
//       "350825": "连城县",
//       "350881": "漳平市"
//   },
//   "350900": {
//       "350902": "蕉城区",
//       "350921": "霞浦县",
//       "350922": "古田县",
//       "350923": "屏南县",
//       "350924": "寿宁县",
//       "350925": "周宁县",
//       "350926": "柘荣县",
//       "350981": "福安市",
//       "350982": "福鼎市"
//   },
//   "360000": {
//       "360100": "南昌市",
//       "360200": "景德镇市",
//       "360300": "萍乡市",
//       "360400": "九江市",
//       "360500": "新余市",
//       "360600": "鹰潭市",
//       "360700": "赣州市",
//       "360800": "吉安市",
//       "360900": "宜春市",
//       "361000": "抚州市",
//       "361100": "上饶市"
//   },
//   "360100": {
//       "360102": "东湖区",
//       "360103": "西湖区",
//       "360104": "青云谱区",
//       "360105": "湾里区",
//       "360111": "青山湖区",
//       "360121": "南昌县",
//       "360122": "新建县",
//       "360123": "安义县",
//       "360124": "进贤县"
//   },
//   "360200": {
//       "360202": "昌江区",
//       "360203": "珠山区",
//       "360222": "浮梁县",
//       "360281": "乐平市"
//   },
//   "360300": {
//       "360302": "安源区",
//       "360313": "湘东区",
//       "360321": "莲花县",
//       "360322": "上栗县",
//       "360323": "芦溪县"
//   },
//   "360400": {
//       "360403": "浔阳区",
//       "360423": "武宁县",
//       "360424": "修水县",
//       "360425": "永修县",
//       "360426": "德安县",
//       "360427": "庐山区",
//       "360428": "都昌县",
//       "360429": "湖口县",
//       "360430": "彭泽县",
//       "360481": "瑞昌市"
//   },
//   "360500": {
//       "360502": "渝水区",
//       "360521": "分宜县"
//   },
//   "360600": {
//       "360602": "月湖区",
//       "360622": "余江县",
//       "360681": "贵溪市"
//   },
//   "360700": {
//       "360702": "章贡区",
//       "360721": "赣县",
//       "360722": "信丰县",
//       "360723": "大余县",
//       "360724": "上犹县",
//       "360725": "崇义县",
//       "360726": "安远县",
//       "360727": "龙南县",
//       "360728": "定南县",
//       "360729": "全南县",
//       "360730": "宁都县",
//       "360731": "于都县",
//       "360732": "兴国县",
//       "360733": "会昌县",
//       "360734": "寻乌县",
//       "360735": "石城县",
//       "360781": "瑞金市",
//       "360782": "南康市"
//   },
//   "360800": {
//       "360802": "吉州区",
//       "360803": "青原区",
//       "360821": "吉安县",
//       "360822": "吉水县",
//       "360823": "峡江县",
//       "360824": "新干县",
//       "360825": "永丰县",
//       "360826": "泰和县",
//       "360827": "遂川县",
//       "360828": "万安县",
//       "360829": "安福县",
//       "360830": "永新县",
//       "360881": "井冈山市"
//   },
//   "360900": {
//       "360902": "袁州区",
//       "360921": "奉新县",
//       "360922": "万载县",
//       "360923": "上高县",
//       "360924": "宜丰县",
//       "360925": "靖安县",
//       "360926": "铜鼓县",
//       "360981": "丰城市",
//       "360982": "樟树市",
//       "360983": "高安市"
//   },
//   "361000": {
//       "361002": "临川区",
//       "361021": "南城县",
//       "361022": "黎川县",
//       "361023": "南丰县",
//       "361024": "崇仁县",
//       "361025": "乐安县",
//       "361026": "宜黄县",
//       "361027": "金溪县",
//       "361028": "资溪县",
//       "361029": "东乡区",
//       "361030": "广昌县"
//   },
//   "361100": {
//       "361102": "信州区",
//       "361121": "上饶县",
//       "361122": "广丰区",
//       "361123": "玉山县",
//       "361124": "铅山县",
//       "361125": "横峰县",
//       "361126": "弋阳县",
//       "361127": "余干县",
//       "361128": "鄱阳县",
//       "361129": "万年县",
//       "361130": "婺源县",
//       "361181": "德兴市"
//   },
//   "370000": {
//       "370100": "济南市",
//       "370200": "青岛市",
//       "370300": "淄博市",
//       "370400": "枣庄市",
//       "370500": "东营市",
//       "370600": "烟台市",
//       "370700": "潍坊市",
//       "370800": "济宁市",
//       "370900": "泰安市",
//       "371000": "威海市",
//       "371100": "日照市",
//       "371300": "临沂市",
//       "371400": "德州市",
//       "371500": "聊城市",
//       "371600": "滨州市",
//       "371700": "荷泽市"
//   },
//   "370100": {
//       "370102": "历下区",
//       "370103": "市中区",
//       "370104": "槐荫区",
//       "370105": "天桥区",
//       "370112": "历城区",
//       "370113": "长清区",
//       "370124": "平阴县",
//       "370125": "济阳县",
//       "370126": "商河县",
//       "370181": "章丘市",
//       "371200": "莱芜区",
//       "371203": "钢城区"
//   },
//   "370200": {
//       "370202": "市南区",
//       "370203": "市北区",
//       "370205": "四方区",
//       "370211": "黄岛区",
//       "370212": "崂山区",
//       "370213": "李沧区",
//       "370214": "城阳区",
//       "370281": "胶州市",
//       "370282": "即墨市",
//       "370283": "平度市",
//       "370284": "胶南市",
//       "370285": "莱西市"
//   },
//   "370300": {
//       "370302": "淄川区",
//       "370303": "张店区",
//       "370304": "博山区",
//       "370305": "临淄区",
//       "370306": "周村区",
//       "370321": "桓台县",
//       "370322": "高青县",
//       "370323": "沂源县"
//   },
//   "370400": {
//       "370402": "市中区",
//       "370403": "薛城区",
//       "370404": "峄城区",
//       "370405": "台儿庄区",
//       "370406": "山亭区",
//       "370481": "滕州市"
//   },
//   "370500": {
//       "370502": "东营区",
//       "370503": "河口区",
//       "370521": "垦利区",
//       "370522": "利津县",
//       "370523": "广饶县"
//   },
//   "370600": {
//       "370602": "芝罘区",
//       "370611": "福山区",
//       "370612": "牟平区",
//       "370613": "莱山区",
//       "370634": "长岛县",
//       "370681": "龙口市",
//       "370682": "莱阳市",
//       "370683": "莱州市",
//       "370684": "蓬莱市",
//       "370685": "招远市",
//       "370686": "栖霞市",
//       "370687": "海阳市"
//   },
//   "370700": {
//       "370702": "潍城区",
//       "370703": "寒亭区",
//       "370704": "坊子区",
//       "370705": "奎文区",
//       "370724": "临朐县",
//       "370725": "昌乐县",
//       "370781": "青州市",
//       "370782": "诸城市",
//       "370783": "寿光市",
//       "370784": "安丘市",
//       "370785": "高密市",
//       "370786": "昌邑市"
//   },
//   "370800": {
//       "370811": "任城区",
//       "370826": "微山县",
//       "370827": "鱼台县",
//       "370828": "金乡县",
//       "370829": "嘉祥县",
//       "370830": "汶上县",
//       "370831": "泗水县",
//       "370832": "梁山县",
//       "370881": "曲阜市",
//       "370882": "兖州市",
//       "370883": "邹城市"
//   },
//   "370900": {
//       "370902": "泰山区",
//       "370903": "岱岳区",
//       "370921": "宁阳县",
//       "370923": "东平县",
//       "370982": "新泰市",
//       "370983": "肥城市"
//   },
//   "371000": {
//       "371002": "环翠区",
//       "371081": "文登市",
//       "371082": "荣成市",
//       "371083": "乳山市"
//   },
//   "371100": {
//       "371102": "东港区",
//       "371103": "岚山区",
//       "371121": "五莲县",
//       "371122": "莒县"
//   },
//   "371300": {
//       "371302": "兰山区",
//       "371311": "罗庄区",
//       "371312": "河东区",
//       "371321": "沂南县",
//       "371322": "郯城县",
//       "371323": "沂水县",
//       "371324": "兰陵县",
//       "371325": "费县",
//       "371326": "平邑县",
//       "371327": "莒南县",
//       "371328": "蒙阴县",
//       "371329": "临沭县"
//   },
//   "371400": {
//       "371402": "德城区",
//       "371421": "陵县",
//       "371422": "宁津县",
//       "371423": "庆云县",
//       "371424": "临邑县",
//       "371425": "齐河县",
//       "371426": "平原县",
//       "371427": "夏津县",
//       "371428": "武城县",
//       "371481": "乐陵市",
//       "371482": "禹城市"
//   },
//   "371500": {
//       "371502": "东昌府区",
//       "371521": "阳谷县",
//       "371522": "莘县",
//       "371523": "茌平县",
//       "371524": "东阿县",
//       "371525": "冠县",
//       "371526": "高唐县",
//       "371581": "临清市"
//   },
//   "371600": {
//       "371602": "滨城区",
//       "371621": "惠民县",
//       "371622": "阳信县",
//       "371623": "无棣县",
//       "371624": "沾化区",
//       "371625": "博兴县",
//       "371626": "邹平县"
//   },
//   "371700": {
//       "371702": "牡丹区",
//       "371721": "曹县",
//       "371722": "单县",
//       "371723": "成武县",
//       "371724": "巨野县",
//       "371725": "郓城县",
//       "371726": "鄄城县",
//       "371727": "定陶区",
//       "371728": "东明县"
//   },
//   "410000": {
//       "410100": "郑州",
//       "410200": "开封",
//       "410300": "洛阳",
//       "410400": "平顶山",
//       "410500": "安阳",
//       "410600": "鹤壁",
//       "410700": "新乡",
//       "410800": "焦作",
//       "410881": "济源市",
//       "410900": "濮阳",
//       "411000": "许昌",
//       "411100": "漯河",
//       "411200": "三门峡",
//       "411300": "南阳",
//       "411400": "商丘",
//       "411500": "信阳",
//       "411600": "周口",
//       "411700": "驻马店"
//   },
//   "410100": {
//       "410102": "中原区",
//       "410103": "二七区",
//       "410104": "管城",
//       "410105": "金水区",
//       "410106": "上街区",
//       "410108": "惠济区",
//       "410122": "中牟县",
//       "410181": "巩义市",
//       "410182": "荥阳市",
//       "410183": "新密市",
//       "410184": "新郑市",
//       "410185": "登封市"
//   },
//   "410200": {
//       "410202": "龙亭区",
//       "410203": "顺河",
//       "410204": "鼓楼区",
//       "410205": "禹王台区",
//       "410221": "杞县",
//       "410222": "通许县",
//       "410223": "尉氏县",
//       "410224": "开封县",
//       "410225": "兰考县"
//   },
//   "410300": {
//       "410302": "老城区",
//       "410303": "西工区",
//       "410304": "廛河",
//       "410305": "涧西区",
//       "410306": "吉利区",
//       "410307": "洛龙区",
//       "410322": "孟津县",
//       "410323": "新安县",
//       "410324": "栾川县",
//       "410325": "嵩县",
//       "410326": "汝阳县",
//       "410327": "宜阳县",
//       "410328": "洛宁县",
//       "410329": "伊川县",
//       "410381": "偃师市"
//   },
//   "410400": {
//       "410402": "新华区",
//       "410403": "卫东区",
//       "410404": "石龙区",
//       "410411": "湛河区",
//       "410421": "宝丰县",
//       "410422": "叶县",
//       "410423": "鲁山县",
//       "410425": "郏县",
//       "410481": "舞钢市",
//       "410482": "汝州市"
//   },
//   "410500": {
//       "410502": "文峰区",
//       "410503": "北关区",
//       "410505": "殷都区",
//       "410506": "龙安区",
//       "410522": "安阳县",
//       "410523": "汤阴县",
//       "410526": "滑县",
//       "410527": "内黄县",
//       "410581": "林州市"
//   },
//   "410600": {
//       "410602": "鹤山区",
//       "410603": "山城区",
//       "410611": "淇滨区",
//       "410621": "浚县",
//       "410622": "淇县"
//   },
//   "410700": {
//       "410702": "红旗区",
//       "410703": "卫滨区",
//       "410704": "凤泉区",
//       "410711": "牧野区",
//       "410721": "新乡县",
//       "410724": "获嘉县",
//       "410725": "原阳县",
//       "410726": "延津县",
//       "410727": "封丘县",
//       "410728": "长垣县",
//       "410781": "卫辉市",
//       "410782": "辉县市"
//   },
//   "410800": {
//       "410802": "解放区",
//       "410803": "中站区",
//       "410804": "马村区",
//       "410811": "山阳区",
//       "410821": "修武县",
//       "410822": "博爱县",
//       "410823": "武陟县",
//       "410825": "温县",
//       "410882": "沁阳市",
//       "410883": "孟州市"
//   },
//   "410900": {
//       "410902": "华龙区",
//       "410922": "清丰县",
//       "410923": "南乐县",
//       "410926": "范县",
//       "410927": "台前县",
//       "410928": "濮阳县"
//   },
//   "411000": {
//       "411002": "魏都区",
//       "411023": "许昌县",
//       "411024": "鄢陵县",
//       "411025": "襄城县",
//       "411081": "禹州市",
//       "411082": "长葛市"
//   },
//   "411100": {
//       "411102": "源汇区",
//       "411103": "郾城区",
//       "411104": "召陵区",
//       "411121": "舞阳县",
//       "411122": "临颍县"
//   },
//   "411200": {
//       "411202": "湖滨区",
//       "411221": "渑池县",
//       "411222": "陕县",
//       "411224": "卢氏县",
//       "411281": "义马市",
//       "411282": "灵宝市"
//   },
//   "411300": {
//       "411302": "宛城区",
//       "411303": "卧龙区",
//       "411321": "南召县",
//       "411322": "方城县",
//       "411323": "西峡县",
//       "411324": "镇平县",
//       "411325": "内乡县",
//       "411326": "淅川县",
//       "411327": "社旗县",
//       "411328": "唐河县",
//       "411329": "新野县",
//       "411330": "桐柏县",
//       "411381": "邓州市"
//   },
//   "411400": {
//       "411402": "梁园区",
//       "411403": "睢阳区",
//       "411421": "民权县",
//       "411422": "睢县",
//       "411423": "宁陵县",
//       "411424": "柘城县",
//       "411425": "虞城县",
//       "411426": "夏邑县",
//       "411481": "永城市"
//   },
//   "411500": {
//       "411502": "师河区",
//       "411503": "平桥区",
//       "411521": "罗山县",
//       "411522": "光山县",
//       "411523": "新县",
//       "411524": "商城县",
//       "411525": "固始县",
//       "411526": "潢川县",
//       "411527": "淮滨县",
//       "411528": "息县"
//   },
//   "411600": {
//       "411602": "川汇区",
//       "411621": "扶沟县",
//       "411622": "西华县",
//       "411623": "商水县",
//       "411624": "沈丘县",
//       "411625": "郸城县",
//       "411626": "淮阳县",
//       "411627": "太康县",
//       "411628": "鹿邑县",
//       "411681": "项城市"
//   },
//   "411700": {
//       "411702": "驿城区",
//       "411721": "西平县",
//       "411722": "上蔡县",
//       "411723": "平舆县",
//       "411724": "正阳县",
//       "411725": "确山县",
//       "411726": "泌阳县",
//       "411727": "汝南县",
//       "411728": "遂平县",
//       "411729": "新蔡县"
//   },
//   "420000": {
//       "420100": "武汉市",
//       "420200": "黄石市",
//       "420300": "十堰市",
//       "420500": "宜昌市",
//       "420600": "襄阳市",
//       "420700": "鄂州市",
//       "420800": "荆门市",
//       "420900": "孝感市",
//       "421000": "荆州市",
//       "421100": "黄冈市",
//       "421200": "咸宁市",
//       "421300": "随州市",
//       "422800": "恩施市",
//       "429004": "仙桃市",
//       "429005": "潜江市",
//       "429006": "天门市",
//       "429021": "神农架林区"
//   },
//   "420100": {
//       "420102": "江岸区",
//       "420103": "江汉区",
//       "420104": "乔口区",
//       "420105": "汉阳区",
//       "420106": "武昌区",
//       "420107": "青山区",
//       "420111": "洪山区",
//       "420112": "东西湖区",
//       "420113": "汉南区",
//       "420114": "蔡甸区",
//       "420115": "江夏区",
//       "420116": "黄陂区",
//       "420117": "新洲区"
//   },
//   "420200": {
//       "420202": "黄石港区",
//       "420203": "西塞山区",
//       "420204": "下陆区",
//       "420205": "铁山区",
//       "420222": "阳新县",
//       "420281": "大冶市"
//   },
//   "420300": {
//       "420302": "茅箭区",
//       "420303": "张湾区",
//       "420321": "郧县",
//       "420322": "郧西县",
//       "420323": "竹山县",
//       "420324": "竹溪县",
//       "420325": "房县",
//       "420381": "丹江口市"
//   },
//   "420500": {
//       "420502": "西陵区",
//       "420503": "伍家岗区",
//       "420504": "点军区",
//       "420505": "猇亭区",
//       "420506": "夷陵区",
//       "420525": "远安县",
//       "420526": "兴山县",
//       "420527": "秭归县",
//       "420528": "长阳",
//       "420529": "五峰",
//       "420581": "宜都市",
//       "420582": "当阳市",
//       "420583": "枝江市"
//   },
//   "420600": {
//       "420602": "襄城区",
//       "420606": "樊城区",
//       "420607": "襄州区",
//       "420624": "南漳县",
//       "420625": "谷城县",
//       "420626": "保康县",
//       "420682": "老河口市",
//       "420683": "枣阳市",
//       "420684": "宜城市"
//   },
//   "420700": {
//       "420702": "梁子湖区",
//       "420703": "华容区",
//       "420704": "鄂城区"
//   },
//   "420800": {
//       "420802": "东宝区",
//       "420804": "掇刀区",
//       "420821": "京山市",
//       "420822": "沙洋县",
//       "420881": "钟祥市"
//   },
//   "420900": {
//       "420902": "孝南区",
//       "420921": "孝昌县",
//       "420922": "大悟县",
//       "420923": "云梦县",
//       "420981": "应城市",
//       "420982": "安陆市",
//       "420984": "汉川市"
//   },
//   "421000": {
//       "421002": "沙市区",
//       "421003": "荆州区",
//       "421022": "公安县",
//       "421023": "监利县",
//       "421024": "江陵县",
//       "421081": "石首市",
//       "421083": "洪湖市",
//       "421087": "松滋市"
//   },
//   "421100": {
//       "421102": "黄州区",
//       "421121": "团风县",
//       "421122": "红安县",
//       "421123": "罗田县",
//       "421124": "英山县",
//       "421125": "浠水县",
//       "421126": "蕲春县",
//       "421127": "黄梅县",
//       "421181": "麻城市",
//       "421182": "武穴市"
//   },
//   "421200": {
//       "421202": "咸安区",
//       "421221": "嘉鱼县",
//       "421222": "通城县",
//       "421223": "崇阳县",
//       "421224": "通山县",
//       "421281": "赤壁市"
//   },
//   "421300": {
//       "421302": "曾都区",
//       "421381": "广水市"
//   },
//   "422800": {
//       "422801": "恩施市",
//       "422802": "利川市",
//       "422822": "建始县",
//       "422823": "巴东县",
//       "422825": "宣恩县",
//       "422826": "咸丰县",
//       "422827": "来凤县",
//       "422828": "鹤峰县"
//   },
//   "430000": {
//       "430100": "长沙市",
//       "430200": "株洲市",
//       "430300": "湘潭市",
//       "430400": "衡阳市",
//       "430500": "邵阳市",
//       "430600": "岳阳市",
//       "430700": "常德市",
//       "430800": "张家界市",
//       "430900": "益阳市",
//       "431000": "郴州市",
//       "431100": "永州市",
//       "431200": "怀化市",
//       "431300": "娄底市",
//       "433100": "湘西"
//   },
//   "430100": {
//       "430102": "芙蓉区",
//       "430103": "天心区",
//       "430104": "岳麓区",
//       "430105": "开福区",
//       "430111": "雨花区",
//       "430121": "长沙县",
//       "430122": "望城县",
//       "430124": "宁乡县",
//       "430181": "浏阳市"
//   },
//   "430200": {
//       "430202": "荷塘区",
//       "430203": "芦淞区",
//       "430204": "石峰区",
//       "430211": "天元区",
//       "430221": "株洲县",
//       "430223": "攸县",
//       "430224": "茶陵县",
//       "430225": "炎陵县",
//       "430281": "醴陵市"
//   },
//   "430300": {
//       "430302": "雨湖区",
//       "430304": "岳塘区",
//       "430321": "湘潭县",
//       "430381": "湘乡市",
//       "430382": "韶山市"
//   },
//   "430400": {
//       "430405": "珠晖区",
//       "430406": "雁峰区",
//       "430407": "石鼓区",
//       "430408": "蒸湘区",
//       "430412": "南岳区",
//       "430421": "衡阳县",
//       "430422": "衡南县",
//       "430423": "衡山县",
//       "430424": "衡东县",
//       "430426": "祁东县",
//       "430481": "耒阳市",
//       "430482": "常宁市"
//   },
//   "430500": {
//       "430502": "双清区",
//       "430503": "大祥区",
//       "430511": "北塔区",
//       "430521": "邵东县",
//       "430522": "新邵县",
//       "430523": "邵阳县",
//       "430524": "隆回县",
//       "430525": "洞口县",
//       "430527": "绥宁县",
//       "430528": "新宁县",
//       "430529": "城步",
//       "430581": "武冈市"
//   },
//   "430600": {
//       "430602": "岳阳楼区",
//       "430603": "云溪区",
//       "430611": "君山区",
//       "430621": "岳阳县",
//       "430623": "华容县",
//       "430624": "湘阴县",
//       "430626": "平江县",
//       "430681": "汨罗市",
//       "430682": "临湘市"
//   },
//   "430700": {
//       "430702": "武陵区",
//       "430703": "鼎城区",
//       "430721": "安乡县",
//       "430722": "汉寿县",
//       "430723": "澧县",
//       "430724": "临澧县",
//       "430725": "桃源县",
//       "430726": "石门县",
//       "430781": "津市市"
//   },
//   "430800": {
//       "430802": "永定区",
//       "430811": "武陵源区",
//       "430821": "慈利县",
//       "430822": "桑植县"
//   },
//   "430900": {
//       "430902": "资阳区",
//       "430903": "赫山区",
//       "430921": "南县",
//       "430922": "桃江县",
//       "430923": "安化县",
//       "430981": "沅江市"
//   },
//   "431000": {
//       "431002": "北湖区",
//       "431003": "苏仙区",
//       "431021": "桂阳县",
//       "431022": "宜章县",
//       "431023": "永兴县",
//       "431024": "嘉禾县",
//       "431025": "临武县",
//       "431026": "汝城县",
//       "431027": "桂东县",
//       "431028": "安仁县",
//       "431081": "资兴市"
//   },
//   "431100": {
//       "431102": "零陵区",
//       "431103": "冷水滩区",
//       "431121": "祁阳县",
//       "431122": "东安县",
//       "431123": "双牌县",
//       "431124": "道县",
//       "431125": "江永县",
//       "431126": "宁远县",
//       "431127": "蓝山县",
//       "431128": "新田县",
//       "431129": "江华"
//   },
//   "431200": {
//       "431202": "鹤城区",
//       "431221": "中方县",
//       "431222": "沅陵县",
//       "431223": "辰溪县",
//       "431224": "溆浦县",
//       "431225": "会同县",
//       "431226": "麻阳",
//       "431227": "新晃",
//       "431228": "芷江",
//       "431229": "靖州",
//       "431230": "通道",
//       "431281": "洪江市"
//   },
//   "431300": {
//       "431302": "娄星区",
//       "431321": "双峰县",
//       "431322": "新化县",
//       "431381": "冷水江市",
//       "431382": "涟源市"
//   },
//   "433100": {
//       "433101": "吉首市",
//       "433122": "泸溪县",
//       "433123": "凤凰县",
//       "433124": "花垣县",
//       "433125": "保靖县",
//       "433126": "古丈县",
//       "433127": "永顺县",
//       "433130": "龙山县"
//   },
//   "440000": {
//       "440102": "东山区",
//       "440103": "荔湾区",
//       "440104": "越秀区",
//       "440105": "海珠区",
//       "440106": "天河区",
//       "440107": "芳村区",
//       "440111": "白云区",
//       "440112": "黄埔区",
//       "440113": "番禺区",
//       "440114": "花都区",
//       "440115": "南沙区",
//       "440183": "增城区",
//       "440184": "从化市"
//   },
//   "440200": {
//       "440203": "武江区",
//       "440204": "浈江区",
//       "440205": "曲江区",
//       "440222": "始兴县",
//       "440224": "仁化县",
//       "440229": "翁源县",
//       "440232": "乳源",
//       "440233": "新丰县",
//       "440281": "乐昌市",
//       "440282": "南雄市"
//   },
//   "440300": {
//       "440303": "罗湖区",
//       "440304": "福田区",
//       "440305": "南山区",
//       "440306": "宝安区",
//       "440307": "龙岗区",
//       "440308": "盐田区",
//       "440309": "坪山区",
//       "440310": "龙华区",
//       "440311": "光明区"
//   },
//   "440400": {
//       "440402": "香洲区",
//       "440403": "斗门区",
//       "440404": "金湾区"
//   },
//   "440500": {
//       "440507": "龙湖区",
//       "440511": "金平区",
//       "440512": "濠江区",
//       "440513": "潮阳区",
//       "440514": "潮南区",
//       "440515": "澄海区",
//       "440523": "南澳县"
//   },
//   "440600": {
//       "440604": "禅城区",
//       "440605": "南海区",
//       "440606": "顺德区",
//       "440607": "三水区",
//       "440608": "高明区"
//   },
//   "440700": {
//       "440703": "蓬江区",
//       "440704": "江海区",
//       "440705": "新会区",
//       "440781": "台山市",
//       "440783": "开平市",
//       "440784": "鹤山市",
//       "440785": "恩平市"
//   },
//   "440800": {
//       "440802": "赤坎区",
//       "440803": "霞山区",
//       "440804": "坡头区",
//       "440811": "麻章区",
//       "440823": "遂溪县",
//       "440825": "徐闻县",
//       "440881": "廉江市",
//       "440882": "雷州市",
//       "440883": "吴川市"
//   },
//   "440900": {
//       "440902": "茂南区",
//       "440903": "茂港区",
//       "440923": "电白县",
//       "440981": "高州市",
//       "440982": "化州市",
//       "440983": "信宜市"
//   },
//   "441200": {
//       "441202": "端州区",
//       "441203": "鼎湖区",
//       "441223": "广宁县",
//       "441224": "怀集县",
//       "441225": "封开县",
//       "441226": "德庆县",
//       "441283": "高要市",
//       "441284": "四会市"
//   },
//   "441300": {
//       "441302": "惠城区",
//       "441303": "惠阳区",
//       "441322": "博罗县",
//       "441323": "惠东县",
//       "441324": "龙门县"
//   },
//   "441400": {
//       "441402": "梅江区",
//       "441421": "梅县",
//       "441422": "大埔县",
//       "441423": "丰顺县",
//       "441424": "五华县",
//       "441426": "平远县",
//       "441427": "蕉岭县",
//       "441481": "兴宁市"
//   },
//   "441500": {
//       "441502": "城区",
//       "441521": "海丰县",
//       "441523": "陆河县",
//       "441581": "陆丰市"
//   },
//   "441600": {
//       "441602": "源城区",
//       "441621": "紫金县",
//       "441622": "龙川县",
//       "441623": "连平县",
//       "441624": "和平县",
//       "441625": "东源县"
//   },
//   "441700": {
//       "441702": "江城区",
//       "441721": "阳西县",
//       "441723": "阳东县",
//       "441781": "阳春市"
//   },
//   "441800": {
//       "441802": "清城区",
//       "441821": "佛冈县",
//       "441823": "阳山县",
//       "441825": "连山",
//       "441826": "连南",
//       "441827": "清新县",
//       "441881": "英德市",
//       "441882": "连州市"
//   },
//   "441900": {
//       "441901": "塘厦",
//       "441904": "莞城"
//   },
//   "442000": {
//       "442021": "中山"
//   },
//   "445100": {
//       "445102": "湘桥区",
//       "445121": "潮安县",
//       "445122": "饶平县"
//   },
//   "445200": {
//       "445202": "榕城区",
//       "445221": "揭东县",
//       "445222": "揭西县",
//       "445224": "惠来县",
//       "445281": "普宁市"
//   },
//   "445300": {
//       "445302": "云城区",
//       "445321": "新兴县",
//       "445322": "郁南县",
//       "445323": "云安县",
//       "445381": "罗定市"
//   },
//   "450000": {
//       "450100": "南宁",
//       "450200": "柳州",
//       "450300": "桂林",
//       "450400": "梧州",
//       "450500": "北海",
//       "450600": "防城港",
//       "450700": "钦州",
//       "450800": "贵港",
//       "450900": "玉林",
//       "451000": "百色",
//       "451100": "贺州",
//       "451200": "河池",
//       "451300": "来宾",
//       "451400": "崇左"
//   },
//   "450100": {
//       "450102": "兴宁区",
//       "450103": "青秀区",
//       "450105": "江南区",
//       "450107": "西乡塘区",
//       "450108": "良庆区",
//       "450109": "邕宁区",
//       "450122": "武鸣县",
//       "450123": "隆安县",
//       "450124": "马山县",
//       "450125": "上林县",
//       "450126": "宾阳县",
//       "450127": "横县"
//   },
//   "450200": {
//       "450202": "城中区",
//       "450203": "鱼峰区",
//       "450204": "柳南区",
//       "450205": "柳北区",
//       "450221": "柳江县",
//       "450222": "柳城县",
//       "450223": "鹿寨县",
//       "450224": "融安县",
//       "450225": "融水",
//       "450226": "三江"
//   },
//   "450300": {
//       "450302": "秀峰区",
//       "450303": "叠彩区",
//       "450304": "象山区",
//       "450305": "七星区",
//       "450311": "雁山区",
//       "450321": "阳朔县",
//       "450322": "临桂县",
//       "450323": "灵川县",
//       "450324": "全州县",
//       "450325": "兴安县",
//       "450326": "永福县",
//       "450327": "灌阳县",
//       "450328": "龙胜",
//       "450329": "资源县",
//       "450330": "平乐县",
//       "450331": "荔蒲县",
//       "450332": "恭城"
//   },
//   "450400": {
//       "450403": "万秀区",
//       "450404": "龙圩区",
//       "450405": "长洲区",
//       "450421": "苍梧县",
//       "450422": "藤县",
//       "450423": "蒙山县",
//       "450481": "岑溪市"
//   },
//   "450500": {
//       "450502": "海城区",
//       "450503": "银海区",
//       "450512": "铁山港区",
//       "450521": "合浦县"
//   },
//   "450600": {
//       "450602": "港口区",
//       "450603": "防城区",
//       "450621": "上思县",
//       "450681": "东兴市"
//   },
//   "450700": {
//       "450702": "钦南区",
//       "450703": "钦北区",
//       "450721": "灵山县",
//       "450722": "浦北县"
//   },
//   "450800": {
//       "450802": "港北区",
//       "450803": "港南区",
//       "450804": "覃塘区",
//       "450821": "平南县",
//       "450881": "桂平市"
//   },
//   "450900": {
//       "450902": "玉州区",
//       "450921": "容县",
//       "450922": "陆川县",
//       "450923": "博白县",
//       "450924": "兴业县",
//       "450981": "北流市"
//   },
//   "451000": {
//       "451002": "右江区",
//       "451021": "田阳县",
//       "451022": "田东县",
//       "451023": "平果县",
//       "451024": "德保县",
//       "451025": "靖西县",
//       "451026": "那坡县",
//       "451027": "凌云县",
//       "451028": "乐业县",
//       "451029": "田林县",
//       "451030": "西林县",
//       "451031": "隆林"
//   },
//   "451100": {
//       "451102": "八步区",
//       "451121": "昭平县",
//       "451122": "钟山县",
//       "451123": "富川"
//   },
//   "451200": {
//       "451202": "金城江区",
//       "451221": "南丹县",
//       "451222": "天峨县",
//       "451223": "凤山县",
//       "451224": "东兰县",
//       "451225": "罗城",
//       "451226": "环江",
//       "451227": "巴马",
//       "451228": "都安",
//       "451229": "大化",
//       "451281": "宜州市"
//   },
//   "451300": {
//       "451302": "兴宾区",
//       "451321": "忻城县",
//       "451322": "象州县",
//       "451323": "武宣县",
//       "451324": "金秀",
//       "451381": "合山市"
//   },
//   "451400": {
//       "451402": "江洲区",
//       "451421": "扶绥县",
//       "451422": "宁明县",
//       "451423": "龙州县",
//       "451424": "大新县",
//       "451425": "天等县",
//       "451481": "凭祥市"
//   },
//   "460000": {
//       "460100": "海口",
//       "460200": "三亚",
//       "469001": "五指山",
//       "469002": "琼海",
//       "469003": "儋州",
//       "469005": "文昌",
//       "469006": "万宁",
//       "469007": "东方",
//       "469025": "定安县",
//       "469026": "屯昌县",
//       "469027": "澄迈县",
//       "469028": "临高县",
//       "469030": "白沙",
//       "469031": "昌江",
//       "469033": "乐东",
//       "469034": "陵水",
//       "469035": "保亭",
//       "469036": "琼中"
//   },
//   "460100": {
//       "460105": "秀英区",
//       "460106": "龙华区",
//       "460107": "琼山区",
//       "460108": "美兰区"
//   },
//   "500000": {
//       "500101": "万州区",
//       "500102": "涪陵区",
//       "500103": "渝中区",
//       "500104": "大渡口区",
//       "500105": "江北区",
//       "500106": "沙坪坝区",
//       "500107": "九龙坡区",
//       "500108": "南岸区",
//       "500109": "北碚区",
//       "500110": "万盛区",
//       "500111": "双桥区",
//       "500112": "渝北区",
//       "500113": "巴南区",
//       "500114": "黔江区",
//       "500115": "长寿区",
//       "500222": "綦江区",
//       "500223": "潼南区",
//       "500224": "铜梁区",
//       "500225": "大足区",
//       "500226": "荣昌区",
//       "500227": "璧山区",
//       "500228": "梁平区",
//       "500229": "城口县",
//       "500230": "丰都县",
//       "500231": "垫江县",
//       "500232": "武隆区",
//       "500233": "忠县",
//       "500234": "开县",
//       "500235": "云阳县",
//       "500236": "奉节县",
//       "500237": "巫山县",
//       "500238": "巫溪县",
//       "500240": "石柱",
//       "500241": "秀山",
//       "500242": "酉阳",
//       "500243": "彭水",
//       "500381": "江津区",
//       "500382": "合川区",
//       "500383": "永川区",
//       "500384": "南川区"
//   },
//   "510000": {
//       "510100": "成都",
//       "510300": "自贡",
//       "510400": "攀枝花",
//       "510500": "泸州",
//       "510600": "德阳",
//       "510700": "绵阳",
//       "510800": "广元",
//       "510900": "遂宁",
//       "511000": "内江",
//       "511100": "乐山",
//       "511300": "南充",
//       "511400": "眉山",
//       "511500": "宜宾",
//       "511600": "广安",
//       "511700": "达州",
//       "511800": "雅安",
//       "511900": "巴中",
//       "512000": "资阳",
//       "513200": "阿坝",
//       "513300": "甘孜",
//       "513400": "凉山"
//   },
//   "510100": {
//       "510104": "锦江区",
//       "510105": "青羊区",
//       "510106": "金牛区",
//       "510107": "武侯区",
//       "510108": "成华区",
//       "510112": "龙泉驿区",
//       "510113": "青白江区",
//       "510114": "新都区",
//       "510115": "温江区",
//       "510121": "金堂县",
//       "510122": "双流区",
//       "510124": "郫都区",
//       "510129": "大邑县",
//       "510131": "蒲江县",
//       "510132": "新津县",
//       "510181": "都江堰市",
//       "510182": "彭州市",
//       "510183": "邛崃市",
//       "510184": "崇州市"
//   },
//   "510300": {
//       "510302": "自流井区",
//       "510303": "贡井区",
//       "510304": "大安区",
//       "510311": "沿滩区",
//       "510321": "荣县",
//       "510322": "富顺县"
//   },
//   "510400": {
//       "510402": "东区",
//       "510403": "西区",
//       "510411": "仁和区",
//       "510421": "米易县",
//       "510422": "盐边县"
//   },
//   "510500": {
//       "510502": "江阳区",
//       "510503": "纳溪区",
//       "510504": "龙马潭区",
//       "510521": "泸县",
//       "510522": "合江县",
//       "510524": "叙永县",
//       "510525": "古蔺县"
//   },
//   "510600": {
//       "510603": "旌阳区",
//       "510623": "中江县",
//       "510626": "罗江县",
//       "510681": "广汉市",
//       "510682": "什邡市",
//       "510683": "绵竹市"
//   },
//   "510700": {
//       "510703": "涪城区",
//       "510704": "游仙区",
//       "510722": "三台县",
//       "510723": "盐亭县",
//       "510724": "安县",
//       "510725": "梓潼县",
//       "510726": "北川",
//       "510727": "平武县",
//       "510781": "江油市"
//   },
//   "510800": {
//       "510812": "朝天区",
//       "510821": "旺苍县",
//       "510822": "青川县",
//       "510823": "剑阁县",
//       "510824": "苍溪县"
//   },
//   "510900": {
//       "510903": "船山区",
//       "510904": "安居区",
//       "510921": "蓬溪县",
//       "510922": "射洪县",
//       "510923": "大英县"
//   },
//   "511000": {
//       "511002": "市中区",
//       "511011": "东兴区",
//       "511024": "威远县",
//       "511025": "资中县",
//       "511028": "隆昌县"
//   },
//   "511100": {
//       "511102": "市中区",
//       "511111": "沙湾区",
//       "511112": "五通桥区",
//       "511113": "金口河区",
//       "511123": "犍为县",
//       "511124": "井研县",
//       "511126": "夹江县",
//       "511129": "沐川县",
//       "511132": "峨边",
//       "511133": "马边",
//       "511181": "峨眉山市"
//   },
//   "511300": {
//       "511302": "顺庆区",
//       "511303": "高坪区",
//       "511304": "嘉陵区",
//       "511321": "南部县",
//       "511322": "营山县",
//       "511323": "蓬安县",
//       "511324": "仪陇县",
//       "511325": "西充县",
//       "511381": "阆中市"
//   },
//   "511400": {
//       "511402": "东坡区",
//       "511421": "仁寿县",
//       "511422": "彭山县",
//       "511423": "洪雅县",
//       "511424": "丹棱县",
//       "511425": "青神县"
//   },
//   "511500": {
//       "511502": "翠屏区",
//       "511522": "南溪区",
//       "511523": "江安县",
//       "511524": "长宁县",
//       "511525": "高县",
//       "511526": "珙县",
//       "511527": "筠连县",
//       "511528": "兴文县",
//       "511529": "屏山县"
//   },
//   "511600": {
//       "511602": "广安区",
//       "511621": "岳池县",
//       "511622": "武胜县",
//       "511623": "邻水县",
//       "511681": "华莹市"
//   },
//   "511700": {
//       "511702": "通川区",
//       "511721": "达县",
//       "511722": "宣汉县",
//       "511723": "开江县",
//       "511724": "大竹县",
//       "511725": "渠县",
//       "511781": "万源市"
//   },
//   "511800": {
//       "511802": "雨城区",
//       "511821": "名山县",
//       "511822": "荥经县",
//       "511823": "汉源县",
//       "511824": "石棉县",
//       "511825": "天全县",
//       "511826": "芦山县",
//       "511827": "宝兴县"
//   },
//   "511900": {
//       "511902": "巴州区",
//       "511921": "通江县",
//       "511922": "南江县",
//       "511923": "平昌县"
//   },
//   "512000": {
//       "512002": "雁江区",
//       "512021": "安岳县",
//       "512022": "乐至县",
//       "512081": "简阳市"
//   },
//   "513200": {
//       "513221": "汶川县",
//       "513222": "理县",
//       "513223": "茂县",
//       "513224": "松潘县",
//       "513225": "九寨沟县",
//       "513226": "金川县",
//       "513227": "小金县",
//       "513228": "黑水县",
//       "513229": "马尔康县",
//       "513230": "壤塘县",
//       "513231": "阿坝县",
//       "513232": "若尔盖县",
//       "513233": "红原县"
//   },
//   "513300": {
//       "513321": "康定县",
//       "513322": "泸定县",
//       "513323": "丹巴县",
//       "513324": "九龙县",
//       "513325": "雅江县",
//       "513326": "道孚县",
//       "513327": "炉霍县",
//       "513328": "甘孜县",
//       "513329": "新龙县",
//       "513330": "德格县",
//       "513331": "白玉县",
//       "513332": "石渠县",
//       "513333": "色达县",
//       "513334": "理塘县",
//       "513335": "巴塘县",
//       "513336": "乡城县",
//       "513337": "稻城县",
//       "513338": "得荣县"
//   },
//   "513400": {
//       "513401": "西昌市",
//       "513422": "木里",
//       "513423": "盐源县",
//       "513424": "德昌县",
//       "513425": "会理县",
//       "513426": "会东县",
//       "513427": "宁南县",
//       "513428": "普格县",
//       "513429": "布拖县",
//       "513430": "金阳县",
//       "513431": "昭觉县",
//       "513432": "喜德县",
//       "513433": "冕宁县",
//       "513434": "越西县",
//       "513435": "甘洛县",
//       "513436": "美姑县",
//       "513437": "雷波县"
//   },
//   "520000": {
//       "520100": "贵阳",
//       "520200": "六盘水",
//       "520300": "遵义",
//       "520400": "安顺",
//       "522200": "铜仁",
//       "522300": "黔西南",
//       "522400": "毕节",
//       "522600": "黔东南",
//       "522700": "黔南"
//   },
//   "520100": {
//       "520102": "南明区",
//       "520103": "云岩区",
//       "520111": "花溪区",
//       "520112": "乌当区",
//       "520113": "白云区",
//       "520114": "小河区",
//       "520121": "开阳县",
//       "520122": "息烽县",
//       "520123": "修文县",
//       "520181": "清镇市"
//   },
//   "520200": {
//       "520201": "钟山区",
//       "520203": "六枝",
//       "520221": "水城县",
//       "520222": "盘县"
//   },
//   "520300": {
//       "520302": "红花岗区",
//       "520303": "汇川区",
//       "520322": "桐梓县",
//       "520323": "绥阳县",
//       "520324": "正安县",
//       "520325": "道真",
//       "520326": "务川",
//       "520327": "凤冈县",
//       "520328": "湄潭县",
//       "520329": "余庆县",
//       "520330": "习水县",
//       "520381": "赤水市",
//       "520382": "仁怀市"
//   },
//   "520400": {
//       "520402": "西秀区",
//       "520421": "平坝区",
//       "520422": "普定县",
//       "520423": "镇宁",
//       "520424": "关岭",
//       "520425": "紫云"
//   },
//   "522200": {
//       "522201": "铜仁市",
//       "522222": "江口县",
//       "522223": "玉屏",
//       "522224": "石阡县",
//       "522225": "思南县",
//       "522226": "印江",
//       "522227": "德江县",
//       "522228": "沿河",
//       "522229": "松桃",
//       "522230": "万山"
//   },
//   "522300": {
//       "522301": "兴义市",
//       "522322": "兴仁县",
//       "522323": "普安县",
//       "522324": "晴隆县",
//       "522325": "贞丰县",
//       "522326": "望谟县",
//       "522327": "册亨县",
//       "522328": "安龙县"
//   },
//   "522400": {
//       "522401": "毕节市",
//       "522422": "大方县",
//       "522423": "黔西县",
//       "522424": "金沙县",
//       "522425": "织金县",
//       "522426": "纳雍县",
//       "522427": "威宁",
//       "522428": "赫章县"
//   },
//   "522600": {
//       "522601": "凯里市",
//       "522622": "黄平县",
//       "522623": "施秉县",
//       "522624": "三穗县",
//       "522625": "镇远县",
//       "522626": "岑巩县",
//       "522627": "天柱县",
//       "522628": "锦屏县",
//       "522629": "剑河县",
//       "522630": "台江县",
//       "522631": "黎平县",
//       "522632": "榕江县",
//       "522633": "从江县",
//       "522634": "雷山县",
//       "522635": "麻江县",
//       "522636": "丹寨县"
//   },
//   "522700": {
//       "522701": "都匀市",
//       "522702": "福泉市",
//       "522722": "荔波县",
//       "522723": "贵定县",
//       "522725": "瓮安县",
//       "522726": "独山县",
//       "522727": "平塘县",
//       "522728": "罗甸县",
//       "522729": "长顺县",
//       "522730": "龙里县",
//       "522731": "惠水县",
//       "522732": "三都"
//   },
//   "530000": {
//       "530100": "昆明",
//       "530300": "曲靖",
//       "530400": "玉溪",
//       "530500": "保山",
//       "530600": "昭通",
//       "530700": "丽江",
//       "530800": "普洱",
//       "530900": "临沧",
//       "532300": "楚雄",
//       "532500": "红河",
//       "532600": "文山",
//       "532800": "西双版纳",
//       "532900": "大理",
//       "533100": "德宏",
//       "533300": "怒江",
//       "533400": "迪庆"
//   },
//   "530100": {
//       "530102": "五华区",
//       "530103": "盘龙区",
//       "530111": "官渡区",
//       "530112": "西山区",
//       "530113": "东川区",
//       "530121": "呈贡县",
//       "530122": "晋宁县",
//       "530124": "富民县",
//       "530125": "宜良县",
//       "530126": "石林",
//       "530127": "嵩明县",
//       "530128": "禄劝",
//       "530129": "寻甸",
//       "530181": "安宁市"
//   },
//   "530300": {
//       "530302": "麒麟区",
//       "530321": "马龙县",
//       "530322": "陆良县",
//       "530323": "师宗县",
//       "530324": "罗平县",
//       "530325": "富源县",
//       "530326": "会泽县",
//       "530328": "沾益县",
//       "530381": "宣威市"
//   },
//   "530400": {
//       "530402": "红塔区",
//       "530421": "江川县",
//       "530422": "澄江县",
//       "530423": "通海县",
//       "530424": "华宁县",
//       "530425": "易门县",
//       "530426": "峨山",
//       "530427": "新平",
//       "530428": "元江"
//   },
//   "530500": {
//       "530502": "隆阳区",
//       "530521": "施甸县",
//       "530522": "腾冲县",
//       "530523": "龙陵县",
//       "530524": "昌宁县"
//   },
//   "530600": {
//       "530602": "昭阳区",
//       "530621": "鲁甸县",
//       "530622": "巧家县",
//       "530623": "盐津县",
//       "530624": "大关县",
//       "530625": "永善县",
//       "530626": "绥江县",
//       "530627": "镇雄县",
//       "530628": "彝良县",
//       "530629": "威信县",
//       "530630": "水富县"
//   },
//   "530700": {
//       "530702": "古城区",
//       "530721": "玉龙",
//       "530722": "永胜县",
//       "530723": "华坪县",
//       "530724": "宁蒗"
//   },
//   "530800": {
//       "530801": "思茅区",
//       "530821": "普洱",
//       "530822": "墨江",
//       "530823": "景东",
//       "530824": "景谷",
//       "530825": "镇沅",
//       "530826": "江城",
//       "530827": "孟连",
//       "530828": "澜沧",
//       "530829": "西盟"
//   },
//   "530900": {
//       "530902": "临翔区",
//       "530921": "凤庆县",
//       "530922": "云县",
//       "530923": "永德县",
//       "530924": "镇康县",
//       "530925": "双江",
//       "530926": "耿马",
//       "530927": "沧源"
//   },
//   "532300": {
//       "532301": "楚雄市",
//       "532322": "双柏县",
//       "532323": "牟定县",
//       "532324": "南华县",
//       "532325": "姚安县",
//       "532326": "大姚县",
//       "532327": "永仁县",
//       "532328": "元谋县",
//       "532329": "武定县",
//       "532331": "禄丰县"
//   },
//   "532500": {
//       "532501": "个旧市",
//       "532502": "开远市",
//       "532522": "蒙自县",
//       "532523": "屏边",
//       "532524": "建水县",
//       "532525": "石屏县",
//       "532526": "弥勒县",
//       "532527": "泸西县",
//       "532528": "元阳县",
//       "532529": "红河县",
//       "532530": "金平",
//       "532531": "绿春县",
//       "532532": "河口"
//   },
//   "532600": {
//       "532621": "文山市",
//       "532622": "砚山县",
//       "532623": "西畴县",
//       "532624": "麻栗坡县",
//       "532625": "马关县",
//       "532626": "丘北县",
//       "532627": "广南县",
//       "532628": "富宁县"
//   },
//   "532800": {
//       "532801": "景洪市",
//       "532822": "勐海县",
//       "532823": "勐腊县"
//   },
//   "532900": {
//       "532901": "大理市",
//       "532922": "漾濞",
//       "532923": "祥云县",
//       "532924": "宾川县",
//       "532925": "弥渡县",
//       "532926": "南涧",
//       "532927": "巍山",
//       "532928": "永平县",
//       "532929": "云龙县",
//       "532930": "洱源县",
//       "532931": "剑川县",
//       "532932": "鹤庆县"
//   },
//   "533100": {
//       "533102": "瑞丽市",
//       "533103": "芒市",
//       "533122": "梁河县",
//       "533123": "盈江县",
//       "533124": "陇川县"
//   },
//   "533300": {
//       "533321": "泸水市",
//       "533323": "福贡县",
//       "533324": "贡山",
//       "533325": "兰坪"
//   },
//   "533400": {
//       "533421": "香格里拉市",
//       "533422": "德钦县",
//       "533423": "维西"
//   },
//   "540000": {
//       "540100": "拉萨",
//       "542100": "昌都",
//       "542200": "山南",
//       "542300": "日喀则地区",
//       "542400": "那曲",
//       "542500": "阿里地区",
//       "542600": "林芝"
//   },
//   "540100": {
//       "540102": "城关区",
//       "540121": "林周县",
//       "540122": "当雄县",
//       "540123": "尼木县",
//       "540124": "曲水县",
//       "540125": "堆龙德庆县",
//       "540126": "达孜区",
//       "540127": "墨竹工卡县"
//   },
//   "542100": {
//       "542121": "昌都县",
//       "542122": "江达县",
//       "542123": "贡觉县",
//       "542124": "类乌齐县",
//       "542125": "丁青县",
//       "542126": "察雅县",
//       "542127": "八宿县",
//       "542128": "左贡县",
//       "542129": "芒康县",
//       "542132": "洛隆县",
//       "542133": "边坝县"
//   },
//   "542200": {
//       "542221": "乃东县",
//       "542222": "扎囊县",
//       "542223": "贡嘎县",
//       "542224": "桑日县",
//       "542225": "琼结县",
//       "542226": "曲松县",
//       "542227": "措美县",
//       "542228": "洛扎县",
//       "542229": "加查县",
//       "542231": "隆子县",
//       "542232": "错那县",
//       "542233": "浪卡子县"
//   },
//   "542300": {
//       "542301": "日喀则市",
//       "542322": "南木林县",
//       "542323": "江孜县",
//       "542324": "定日县",
//       "542325": "萨迦县",
//       "542326": "拉孜县",
//       "542327": "昂仁县",
//       "542328": "谢通门县",
//       "542329": "白朗县",
//       "542330": "仁布县",
//       "542331": "康马县",
//       "542332": "定结县",
//       "542333": "仲巴县",
//       "542334": "亚东县",
//       "542335": "吉隆县",
//       "542336": "聂拉木县",
//       "542337": "萨嘎县",
//       "542338": "岗巴县"
//   },
//   "542400": {
//       "542422": "嘉黎县",
//       "542423": "比如县",
//       "542424": "聂荣县",
//       "542425": "安多县",
//       "542426": "申扎县",
//       "542427": "索县",
//       "542428": "班戈县",
//       "542429": "巴青县",
//       "542430": "尼玛县",
//       "542431": "双湖县"
//   },
//   "542500": {
//       "542521": "普兰县",
//       "542522": "札达县",
//       "542523": "噶尔县",
//       "542524": "日土县",
//       "542525": "革吉县",
//       "542526": "改则县",
//       "542527": "措勤县"
//   },
//   "542600": {
//       "542621": "林芝县",
//       "542622": "工布江达县",
//       "542623": "米林县",
//       "542624": "墨脱县",
//       "542625": "波密县",
//       "542626": "察隅县",
//       "542627": "朗县"
//   },
//   "610000": {
//       "610100": "西安",
//       "610200": "铜川",
//       "610300": "宝鸡",
//       "610400": "咸阳",
//       "610500": "渭南",
//       "610600": "延安",
//       "610700": "汉中",
//       "610800": "榆林",
//       "610900": "安康",
//       "611000": "商洛"
//   },
//   "610100": {
//       "610102": "新城区",
//       "610103": "碑林区",
//       "610104": "莲湖区",
//       "610111": "灞桥区",
//       "610112": "未央区",
//       "610113": "雁塔区",
//       "610114": "阎良区",
//       "610115": "临潼区",
//       "610116": "长安区",
//       "610122": "蓝田县",
//       "610124": "周至县",
//       "610125": "户县",
//       "610126": "高陵县"
//   },
//   "610200": {
//       "610202": "王益区",
//       "610203": "印台区",
//       "610204": "耀州区",
//       "610222": "宜君县"
//   },
//   "610300": {
//       "610302": "渭滨区",
//       "610303": "金台区",
//       "610304": "陈仓区",
//       "610322": "凤翔县",
//       "610323": "岐山县",
//       "610324": "扶风县",
//       "610326": "眉县",
//       "610327": "陇县",
//       "610328": "千阳县",
//       "610329": "麟游县",
//       "610330": "凤县",
//       "610331": "太白县"
//   },
//   "610400": {
//       "610402": "秦都区",
//       "610403": "杨凌区",
//       "610404": "渭城区",
//       "610422": "三原县",
//       "610423": "泾阳县",
//       "610424": "乾县",
//       "610425": "礼泉县",
//       "610426": "永寿县",
//       "610427": "彬州市",
//       "610428": "长武县",
//       "610429": "旬邑县",
//       "610430": "淳化县",
//       "610431": "武功县",
//       "610481": "兴平市"
//   },
//   "610500": {
//       "610502": "临渭区",
//       "610521": "华县",
//       "610522": "潼关县",
//       "610523": "大荔县",
//       "610524": "合阳县",
//       "610525": "澄城县",
//       "610526": "蒲城县",
//       "610527": "白水县",
//       "610528": "富平县",
//       "610581": "韩城市",
//       "610582": "华阴市"
//   },
//   "610600": {
//       "610602": "宝塔区",
//       "610621": "延长县",
//       "610622": "延川县",
//       "610623": "子长县",
//       "610624": "安塞区",
//       "610625": "志丹县",
//       "610626": "吴旗县",
//       "610627": "甘泉县",
//       "610628": "富县",
//       "610629": "洛川县",
//       "610630": "宜川县",
//       "610631": "黄龙县",
//       "610632": "黄陵县"
//   },
//   "610700": {
//       "610702": "汉台区",
//       "610721": "南郑区",
//       "610722": "城固县",
//       "610723": "洋县",
//       "610724": "西乡县",
//       "610725": "勉县",
//       "610726": "宁强县",
//       "610727": "略阳县",
//       "610728": "镇巴县",
//       "610729": "留坝县",
//       "610730": "佛坪县"
//   },
//   "610800": {
//       "610802": "榆阳区",
//       "610821": "神木县",
//       "610822": "府谷县",
//       "610823": "横山县",
//       "610824": "靖边县",
//       "610825": "定边县",
//       "610826": "绥德县",
//       "610827": "米脂县",
//       "610828": "佳县",
//       "610829": "吴堡县",
//       "610830": "清涧县",
//       "610831": "子洲县"
//   },
//   "610900": {
//       "610902": "汉滨区",
//       "610921": "汉阴县",
//       "610922": "石泉县",
//       "610923": "宁陕县",
//       "610924": "紫阳县",
//       "610925": "岚皋县",
//       "610926": "平利县",
//       "610927": "镇坪县",
//       "610928": "旬阳县",
//       "610929": "白河县"
//   },
//   "611000": {
//       "611002": "商州区",
//       "611021": "洛南县",
//       "611022": "丹凤县",
//       "611023": "商南县",
//       "611024": "山阳县",
//       "611025": "镇安县",
//       "611026": "柞水县"
//   },
//   "620000": {
//       "620100": "兰州",
//       "620200": "嘉峪关",
//       "620300": "金昌",
//       "620400": "白银",
//       "620500": "天水",
//       "620600": "武威",
//       "620700": "张掖",
//       "620800": "平凉",
//       "620900": "酒泉",
//       "621000": "庆阳",
//       "621100": "定西",
//       "621200": "陇南",
//       "622900": "临夏",
//       "623000": "甘南"
//   },
//   "620100": {
//       "620102": "城关区",
//       "620103": "七里河区",
//       "620104": "西固区",
//       "620105": "安宁区",
//       "620111": "红古区",
//       "620121": "永登县",
//       "620122": "皋兰县",
//       "620123": "榆中县"
//   },
//   "620200": {
//       "620200": "嘉峪关"
//   },
//   "620300": {
//       "620302": "金川区",
//       "620321": "永昌县"
//   },
//   "620400": {
//       "620402": "白银区",
//       "620403": "平川区",
//       "620421": "靖远县",
//       "620422": "会宁县",
//       "620423": "景泰县"
//   },
//   "620500": {
//       "620502": "秦州区",
//       "620503": "麦积区",
//       "620521": "清水县",
//       "620522": "秦安县",
//       "620523": "甘谷县",
//       "620524": "武山县",
//       "620525": "张家川"
//   },
//   "620600": {
//       "620602": "凉州区",
//       "620621": "民勤县",
//       "620622": "古浪县",
//       "620623": "天祝"
//   },
//   "620700": {
//       "620702": "甘州区",
//       "620721": "肃南",
//       "620722": "民乐县",
//       "620723": "临泽县",
//       "620724": "高台县",
//       "620725": "山丹县"
//   },
//   "620800": {
//       "620802": "崆峒区",
//       "620821": "泾川县",
//       "620822": "灵台县",
//       "620823": "崇信县",
//       "620824": "华亭县",
//       "620825": "庄浪县",
//       "620826": "静宁县"
//   },
//   "620900": {
//       "620902": "肃州区",
//       "620921": "金塔县",
//       "620922": "安西县",
//       "620923": "肃北",
//       "620924": "阿克",
//       "620981": "玉门市",
//       "620982": "敦煌市"
//   },
//   "621000": {
//       "621002": "西峰区",
//       "621021": "庆城县",
//       "621022": "环县",
//       "621023": "华池县",
//       "621024": "合水县",
//       "621025": "正宁县",
//       "621026": "宁县",
//       "621027": "镇原县"
//   },
//   "621100": {
//       "621102": "安定区",
//       "621121": "通渭县",
//       "621122": "陇西县",
//       "621123": "渭源县",
//       "621124": "临洮县",
//       "621125": "漳县",
//       "621126": "岷县"
//   },
//   "621200": {
//       "621202": "武都区",
//       "621221": "成县",
//       "621222": "文县",
//       "621223": "宕昌县",
//       "621224": "康县",
//       "621225": "西和县",
//       "621226": "礼县",
//       "621227": "徽县",
//       "621228": "两当县"
//   },
//   "622900": {
//       "622901": "临夏市",
//       "622921": "临夏县",
//       "622922": "康乐县",
//       "622923": "永靖县",
//       "622924": "广河县",
//       "622925": "和政县",
//       "622926": "东乡",
//       "622927": "积石山"
//   },
//   "623000": {
//       "623001": "合作市",
//       "623021": "临潭县",
//       "623022": "卓尼县",
//       "623023": "舟曲县",
//       "623024": "迭部县",
//       "623025": "玛曲县",
//       "623026": "碌曲县",
//       "623027": "夏河县"
//   },
//   "630000": {
//       "630100": "西宁",
//       "632100": "海东",
//       "632200": "海北",
//       "632300": "黄南",
//       "632500": "海南省",
//       "632600": "果洛",
//       "632700": "玉树",
//       "632800": "海西"
//   },
//   "630100": {
//       "630102": "城东区",
//       "630103": "城中区",
//       "630104": "城西区",
//       "630105": "城北区",
//       "630121": "大通",
//       "630122": "湟中县",
//       "630123": "湟源县"
//   },
//   "632100": {
//       "632121": "平安县",
//       "632122": "民和",
//       "632123": "乐都县",
//       "632126": "互助",
//       "632127": "化隆",
//       "632128": "循化"
//   },
//   "632200": {
//       "632221": "门源",
//       "632222": "祁连县",
//       "632223": "海晏县",
//       "632224": "刚察县"
//   },
//   "632300": {
//       "632321": "同仁县",
//       "632322": "尖扎县",
//       "632323": "泽库县",
//       "632324": "河南省"
//   },
//   "632500": {
//       "632521": "共和县",
//       "632522": "同德县",
//       "632523": "贵德县",
//       "632524": "兴海县",
//       "632525": "贵南县"
//   },
//   "632600": {
//       "632621": "玛沁县",
//       "632622": "班玛县",
//       "632623": "甘德县",
//       "632624": "达日县",
//       "632625": "久治县",
//       "632626": "玛多县"
//   },
//   "632700": {
//       "632721": "玉树市",
//       "632722": "杂多县",
//       "632723": "称多县",
//       "632724": "治多县",
//       "632725": "囊谦县",
//       "632726": "曲麻莱县"
//   },
//   "632800": {
//       "632801": "格尔木市",
//       "632802": "德令哈市",
//       "632821": "乌兰县",
//       "632822": "都兰县",
//       "632823": "天峻县",
//       "632824": "茫崖市"
//   },
//   "640000": {
//       "640100": "银川",
//       "640200": "石嘴山",
//       "640300": "吴忠",
//       "640400": "固原",
//       "640500": "中卫"
//   },
//   "640100": {
//       "640104": "兴庆区",
//       "640105": "西夏区",
//       "640106": "金凤区",
//       "640121": "永宁县",
//       "640122": "贺兰县",
//       "640181": "灵武市"
//   },
//   "640200": {
//       "640202": "大武口区",
//       "640205": "惠农区",
//       "640221": "平罗县"
//   },
//   "640300": {
//       "640302": "利通区",
//       "640323": "盐池县",
//       "640324": "同心县",
//       "640381": "青铜峡市"
//   },
//   "640400": {
//       "640402": "原州区",
//       "640422": "西吉县",
//       "640423": "隆德县",
//       "640424": "泾源县",
//       "640425": "彭阳县"
//   },
//   "640500": {
//       "640502": "沙坡头区",
//       "640521": "中宁县",
//       "640522": "海原县"
//   },
//   "650000": {
//       "650100": "乌鲁木齐",
//       "650200": "克拉玛依",
//       "652100": "吐鲁番地区",
//       "652200": "哈密地区",
//       "652300": "昌吉",
//       "652700": "博尔塔拉蒙古",
//       "652800": "巴音郭楞蒙古",
//       "652900": "阿克苏",
//       "653000": "克孜勒苏柯尔克孜",
//       "653100": "喀什",
//       "653200": "和田",
//       "654000": "伊犁哈萨克",
//       "659001": "石河子市",
//       "659002": "阿拉尔",
//       "659003": "图木舒克",
//       "659004": "五家渠"
//   },
//   "650100": {
//       "650102": "天山区",
//       "650103": "沙依巴克区",
//       "650104": "新市区",
//       "650105": "水磨沟区",
//       "650106": "头屯河区",
//       "650107": "达坂城区",
//       "650108": "米东区",
//       "650121": "乌鲁木齐县"
//   },
//   "650200": {
//       "650202": "独山子区",
//       "650203": "克拉玛依区",
//       "650204": "白碱滩区",
//       "650205": "乌尔禾区"
//   },
//   "652100": {
//       "652101": "吐鲁番市",
//       "652122": "鄯善县",
//       "652123": "托克逊县"
//   },
//   "652200": {
//       "652201": "哈密市",
//       "652222": "巴里坤哈萨克",
//       "652223": "伊吾县"
//   },
//   "652300": {
//       "652301": "昌吉市",
//       "652302": "阜康市",
//       "652303": "米泉市",
//       "652323": "呼图壁县",
//       "652324": "玛纳斯县",
//       "652325": "奇台县",
//       "652327": "吉木萨尔县",
//       "652328": "木垒哈萨克"
//   },
//   "652700": {
//       "652701": "博乐市",
//       "652722": "精河县",
//       "652723": "温泉县"
//   },
//   "652800": {
//       "652801": "库尔勒市",
//       "652822": "轮台县",
//       "652823": "尉犁县",
//       "652824": "若羌县",
//       "652825": "且末县",
//       "652826": "焉耆",
//       "652827": "和静县",
//       "652828": "和硕县",
//       "652829": "博湖县"
//   },
//   "652900": {
//       "652901": "阿克苏市",
//       "652922": "温宿县",
//       "652923": "库车县",
//       "652924": "沙雅县",
//       "652925": "新和县",
//       "652926": "拜城县",
//       "652927": "乌什县",
//       "652928": "阿瓦提县",
//       "652929": "柯坪县"
//   },
//   "653000": {
//       "653001": "阿图什市",
//       "653022": "阿克陶县",
//       "653023": "阿合奇县",
//       "653024": "乌恰县"
//   },
//   "653100": {
//       "653101": "喀什市",
//       "653121": "疏附县",
//       "653122": "疏勒县",
//       "653123": "英吉沙县",
//       "653124": "泽普县",
//       "653125": "莎车县",
//       "653126": "叶城县",
//       "653127": "麦盖提县",
//       "653128": "岳普湖县",
//       "653129": "伽师县",
//       "653130": "巴楚县",
//       "653131": "塔什库尔干塔吉克"
//   },
//   "653200": {
//       "653201": "和田市",
//       "653221": "和田县",
//       "653222": "墨玉县",
//       "653223": "皮山县",
//       "653224": "洛浦县",
//       "653225": "策勒县",
//       "653226": "于田县",
//       "653227": "民丰县"
//   },
//   "654000": {
//       "654002": "伊宁市",
//       "654003": "奎屯市",
//       "654021": "伊宁县",
//       "654022": "察布查尔锡伯",
//       "654023": "霍城县",
//       "654024": "巩留县",
//       "654025": "新源县",
//       "654026": "昭苏县",
//       "654027": "特克斯县",
//       "654028": "尼勒克县",
//       "654200": "塔城",
//       "654300": "阿勒泰"
//   },
//   "710000": {
//       "711000": "台北",
//       "712000": "新北",
//       "713000": "桃园",
//       "714000": "台中",
//       "715000": "台南",
//       "716000": "高雄"
//   },
//   "711000": {
//       "711001": "内湖",
//       "711002": "南港",
//       "711003": "中正",
//       "711004": "万华",
//       "711005": "大同",
//       "711006": "中山",
//       "711007": "松山",
//       "711008": "大安",
//       "711009": "信义",
//       "711010": "文山",
//       "711011": "士林",
//       "711012": "北投"
//   },
//   "712000": {
//       "712001": "板桥",
//       "712002": "汐止",
//       "712003": "新店"
//   },
//   "810000": {
//       "811001": "深水埗",
//       "811002": "油尖旺",
//       "811003": "九龙城",
//       "811004": "黄大仙",
//       "811005": "观塘",
//       "811006": "北区",
//       "811007": "大埔",
//       "811008": "沙田",
//       "811009": "西贡",
//       "811010": "元朗",
//       "811011": "屯门",
//       "811012": "荃湾",
//       "811013": "葵青",
//       "811014": "离岛",
//       "811015": "中西",
//       "811016": "湾仔",
//       "811017": "东区",
//       "811018": "南区"
//   },
//   "820000": {
//       "821001": "花地玛堂",
//       "821002": "圣安多尼堂",
//       "821003": "大堂",
//       "821004": "望德堂",
//       "821005": "风顺堂",
//       "821006": "嘉模堂",
//       "821007": "圣方济各堂",
//       "821008": "路凼"
//   }
// }
// 创建一个映射表，用于快速查找i中的匹配项
// let cityMapping = {};
// for (let fullCityName in i) {
//   cityMapping[fullCityName] = i[fullCityName];
// }

// 遍历DISTRICTS，用映射表中的匹配项替换简写
// for (let provinceCode in DISTRICTS) {
//   if (provinceCode !== '100000') { // 跳过顶级分类
//     for (let cityCode in DISTRICTS[provinceCode]) {
//       let cityNameInDISTRICTS = DISTRICTS[provinceCode][cityCode];
      
//       // 检查cityNameInDISTRICTS是否在映射表中
//       if (cityMapping.hasOwnProperty(cityNameInDISTRICTS)) {
//         DISTRICTS[provinceCode][cityCode] = cityMapping[cityNameInDISTRICTS];
//       }
//     }
//   }
// }

// // 此时，DISTRICTS中的城市名称已经根据i中的数据进行了替换。
// console.log(DISTRICTS);
  export{
    provincial,
    provincialList
  }