import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import VueWechatTitle from 'vue-wechat-title' //可以动态修改浏览器标题的插件
import 'element-plus/dist/index.css'
import App from './App.vue'

import router from './router'

import pinia from './stores' 
const app = createApp(App)

app.use(router)
app.use(pinia)
app.use(ElementPlus)
app.use(VueWechatTitle)
// 全局路由守卫
router.beforeEach((to, from, next) => {
    // 获取目标路由的元信息
    const title = to.meta.title || '56360供应链 - 联接你我他';
  // console.log(to);
    // 更新浏览器标题
    document.title = title;
  if(to.path=="/redirec/SearchLine"){
    document.title = to.query.sstxt+"公司"+' - 56360供应链';
    // console.log(998877);
  }
    next();
  })
  
app.mount('#app')