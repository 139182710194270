
import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/index.vue'
const router = createRouter({
  history: createWebHistory(),
  routes: [

    
   
    {
      path: "/",
      redirect: '/redirec/SpecialLine', // 添加重定向到SpecialLine
      component: () => import('@/views/index.vue'),
        meta:{
            title:'56360供应链 - 联接你我他',
            keepAlive: true,
          },
      children: [
        // {
        //   path: "/redirect/:path(.*)",
        //   component: () => import("@/views/redirect/index.vue"),
        // },
        {
          path: '/redirec/SpecialLine',
          name: 'SpecialLine',
          component: () => import('@/components/SpecialLine.vue'),
          meta:{
            title:'56360供应链 - 联接你我他',
            keepAlive: true,
          }
        },
        {
          path: '/redirec/DirectLine',
          name: 'DirectLine',
          component: () => import('@/components/DirectLine.vue'),
          meta:{
            title:'56360供应链 - 找直销',
            keepAlive: true,
          }
        },
      
        {
          path: '/redirec/SearchLine',
          name: 'SearchLine',
          component: () => import('@/components/SearchLine.vue'),
          meta:{
            title:'',
            keepAlive: true,
          }
        },
      ],
    },
    {
      path: "/Detail",
      name: 'Detail',
      component: () => import('@/views/Detail.vue'),
        meta:{
            title:'56360供应链 - 联接你我他',
            keepAlive: true,
          },
        }
   
  ]
})
export default router